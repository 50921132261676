import { Box } from "@mui/material";
import SecondaryBox from "../../atoms/box/SecondaryBox";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { AssetFilesLoader } from "../../organisms/skeleton-loaders/AssetFilesLoader";
import { datasetVersionColumnsVisibility } from "../../utilities/columns-visibility/AssetDatasetDetailColumnsVisibility";
import { ListViewLoader } from "./ListViewLoader";

export const AssetDatasetDetailsLoader = () => {
  return (
    <Box display="flex" flexDirection="column" gap="15px">
      <SecondaryBox>
        <AssetFilesLoader />
      </SecondaryBox>
      <SecondaryBox>
        <RectangularLoader height="40px" />
      </SecondaryBox>
      <SecondaryBox>
        <RectangularLoader height="40px" />
      </SecondaryBox>
      <SecondaryBox>
        <RectangularLoader height="40px" />
      </SecondaryBox>
      <SecondaryBox>
        <RectangularLoader height="40px" />
      </SecondaryBox>
      <SecondaryBox>
        <RectangularLoader height="40px" />
      </SecondaryBox>
      <ListViewLoader
        paddingBottom={false}
        columnsVisibility={datasetVersionColumnsVisibility}
        tableSize="small"
      />
    </Box>
  );
};
