import { useMediaQuery } from "@mui/material";
import { format, parseISO } from "date-fns";
import moment from "moment";
import config from "../config/config";
import {
  ExecutionTask,
  ICustomInputValueProps,
  PartialParameter,
  PartialUser,
  RegistryFieldType,
  RuleLogicOperator,
  SemanticSimiliarityItem,
} from "../models/types";

export const DEFAULT_INTERNAL_BORDER_RADIUS = "3px";
export const DEFAULT_ICON_HEIGHT = "36px";
export const DEFAULT_ICON_WIDTH = "36px";
export const AI_USE_CASE_REGISTRY_ID = "65310273-d576-47d3-9966-ffe2278eb6eb";
export const ASSESSMENT_OWNER_FIELD_ID = "92a7dd16-f535-4150-96aa-a6fbabacc69e";
export const USE_CASE_OWNER_FIELD_ID = "2239fe43-cc72-4423-a991-d4e8985698cb";
export const DESCRIPTION_FIELD_ID = "6d131ad1-f9c0-4b31-bfa6-c025cbe4cd3b";
export const DEVELOPMENT_TEAM_FIELD_ID = "be269f05-1230-4984-8ba4-f42474a9b801";
export const AI_USE_CASE_TYPE_REGISTRY = "116f159e-877f-4d84-9b30-a987a53a4af3";
export const REVIEWER_FIELD_ID = "f37be9fe-167d-4077-8b91-5261736b52e2";
export const INTENDED_USES_REGISTRY_ID = "1ae1a84f-abd0-4e99-9e81-dc0eabd6c74a";
export const INTENDED_USE_APPLICABLE_USE_CASE_FIELD_ID = "0f9e2101-910f-4063-95dc-2ec71fd64494";
export const INTENDED_USE_DESCRIPTION_FIELD_ID = "ade4e6d3-4b90-4742-a242-f86dd36054dc";
export const AI_VENDOR_REGISTRY_TYPE_ID = "86b78cda-15d4-4d24-b023-888e63bd4abc";
export const AI_VENDOR_REGISTRY_ID = "dd07734e-0204-4c0a-ad2a-ff2d4bded24c";
export const POLICY_ASSET_TYPE_ID = "596ee86f-b587-496c-b8b5-3e02cc99991e";
export const DOCUMENTATION_ASSET_TYPE_ID = "4c5269c8-2108-4b9c-830f-a6d1de3279b5";

export const ScreenSmallerThen = (size: string = "900px") => {
  return useMediaQuery(`(max-width: ${size})`);
};

export const dateDisplay = (date: Date | string | number | null | undefined): string => {
  return date == null ? "-" : moment(date).format("MMMM/DD/YYYY");
};

export const dateDisplayWithNumbers = (date: Date | string | number | null | undefined): string => {
  return date == null ? "-" : moment(date).format("MM/DD/YYYY");
};

export const defaultDateDisplay = (date: string | null) => {
  try {
    return date === null ? "-" : format(parseISO(date), "MM/dd/yyyy");
  } catch (error) {
    return date;
  }
};

export const dateDisplayWithMonthInText = (date: string): string => {
  try {
    return date === null ? "-" : format(parseISO(date), "MMM dd, yyyy");
  } catch (error) {
    return date;
  }
};

export const dateDisplayWithTime = (date: string): string => {
  try {
    return date === null ? "-" : format(parseISO(date), "MM/dd/yyyy, HH:mm");
  } catch (error) {
    return date;
  }
};

export const currencyWithSymbol = (amount: number, currency: string) => {
  try {
    const currencyFormat = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency.toLocaleUpperCase(),
      maximumFractionDigits: 0,
    });

    return currencyFormat.format(amount);
  } catch (error) {
    return currency + amount;
  }
};

export const Capitalize = (word?: string) => {
  return `${word?.charAt(0)?.toUpperCase()}${word?.slice(1)}`;
};

export const getLastPathByRegex = (path: string) => {
  const regex = /\/([^\/]*)\/?$/;
  const match = path.match(regex);
  return match ? match[1] : "";
};

export const getFinalNumberFromPath = (path: string): string => {
  const regex = /(\d+)$/;
  const match = path.match(regex);
  return match ? match[1] : "";
};

export const isCreatedByFairo = (user: PartialUser) => user.id === config.ids.fairoUserUUID;

export const isListType = (fieldType: RegistryFieldType) => {
  return fieldType.includes("list");
};

export const getDefaultCustomFieldStartValue = (fieldType: RegistryFieldType) => {
  switch (fieldType) {
    case "String":
      return "";
    case "Number":
      return "";
    case "JSON":
      return "{}";
    case "Date":
      return null;
    case "User":
      return "";
    case "Asset":
      return "";
    case "User list":
      return [];
    case "Asset list":
      return [];
    case "Role list":
      return [];
    case "Workflow list":
      return [];
    case "Action list":
      return [];
    case "Metric list":
      return [];
    case "Test Case list":
      return [];
    case "Test Run list":
      return [];
    case "Action":
      return [];
    case "Boolean":
      return "false";
    case "MLFlow Model":
      return "";
    case "Metric":
      return "";
    case "Monetary Value":
      return "";
    case "Number with Unit":
      return "";
    case "Percentage":
      return "";
    case "Rating Level":
      return "";
    case "Role":
      return "";
    case "Test Case":
      return "";
    case "Test Run":
      return "";
    case "Workflow":
      return "";
    case "Policy":
      return "";
    case "Policy list":
      return [];
    case "Task":
      return "";
    case "Task list":
      return [];
    case "Reference":
      return "";
    case "Reference list":
      return [];
    case "Integration":
      return "";
    case "Integration list":
      return [];
    case "Resource":
      return "";
    case "Resource list":
      return [];
    case "Parameter":
      return "";
    case "Parameter list":
      return [];
    case "Framework":
      return "";
    case "Framework list":
      return [];
    case "Element":
      return "";
    case "Element list":
      return [];
    case "Evidence":
      return "";
    case "Evidence list":
      return [];
    case "Hyperlink":
      return "";
    case "Image":
      return "";
    case "Report":
      return "";
    case "Questionnaire":
      return "";
    case "MLFlow Data":
      return "";
    case "Metric Result":
      return "";
    case "Test Result":
      return "";
    case "Department":
      return "";
    case "Department list":
      return [];
    case "Impact Assessment":
      return "";
    case "Name and Description":
      return JSON.stringify({
        name: "",
        description: "",
      });
    case "Name and Description list":
      return [];
    case "Text Area":
      return "";
    case "External Link":
      return [];
    case "IA Stakeholder":
      return [];
    case "Geographic Areas":
      return [];
    case "Languages":
      return [];
    case "Yes or No":
      return "";
    case "String list":
      return [];
    case "Use Case":
      return "";
    case "Use Case list":
      return [];
    case "Risk Registry":
      return "";
    case "Tag":
      return "";
    case "Tag List":
      return [];
    case "Formatted Text":
      return "";
    case "Question":
      return "";
    case "Potential Harm":
      return [];
    case "Vendor":
      return "";
    case "Jira Ticket":
      return "";
    case "Confluence Page":
      return "";
    case "Data Origin":
      return null;
    case "Risk Level":
      return null;
    case "Relationship Complexity":
      return "";
    case "Single Selection":
      return null;
    case "File or Link":
      return "{}";
    case "Asset with Description list":
      return "[]";
    case "Metric with Description list":
      return "[]";
    case "Metric Result Test Result list":
      return "[]";
    case "Dataset list":
      return "[]";
    case "Model list":
      return "[]";
    case "Vendor Procurement Requirement":
      return "";
    case "Personal Information":
      return "{}";
    case "Multiple Selection":
      return "[]";
    case "Plugin Inference Table":
      return "";
    default:
      // below line makes sure the switch case handles all custom field types
      const _: never = fieldType;
      return "";
  }
};

export const validateParameterValues = async (
  parameters: PartialParameter[],
  values: ICustomInputValueProps[],
  validateEmpty: boolean,
  onUpdate: (data: ICustomInputValueProps[]) => void
) => {
  const newValuesArray = values;
  parameters.forEach((parameter) => {
    // find value from parameter inside values, if value doesn't satisfy field type, update error to true and error message
    const inputValue = newValuesArray.find((p) => p.id === parameter.id);
    if (!inputValue) throw new Error("Failed to find parameter value");
    if (validateEmpty && !inputValue?.value) {
      inputValue!.error = true;
      inputValue!.errorMessage = "Please enter a value";
    }
    const isNotEmpty = inputValue.value !== "";
    if (isNotEmpty && inputValue.field_type === "JSON" && typeof inputValue.value === "string") {
      try {
        JSON.parse(inputValue?.value);
      } catch {
        inputValue!.error = true;
        inputValue!.errorMessage = "Invalid JSON";
      }
    } else if (
      isNotEmpty &&
      inputValue.field_type === "Number" &&
      typeof inputValue.value === "string"
    ) {
      if (isNaN(Number(inputValue?.value)) || isNaN(parseFloat(inputValue?.value))) {
        inputValue!.error = true;
        inputValue!.errorMessage = "Not a Number";
      }
    } else if (isNotEmpty && ["User", "Date", "String"].includes(inputValue.field_type!)) {
      if (!inputValue.value) {
        inputValue!.error = true;
        inputValue!.errorMessage = "Cannot be empty";
      }
    }
  });
  onUpdate([...newValuesArray]);
};

export const breakDescriptionIn250Chars = (description?: string, maxChar = 250): string => {
  if (!description || description === "") {
    return "";
  }
  const descriptionWithoutHTML = description.replace(/<[^>]*>?/gm, "");
  return descriptionWithoutHTML.length > maxChar
    ? `${descriptionWithoutHTML.slice(0, maxChar)}...`
    : descriptionWithoutHTML;
};

export const breakRichTextDescriptionIn250Chars = (description?: string, maxChar = 250) => {
  if (!description || description === "") {
    return "";
  }
  return description.length > maxChar ? `${description.slice(0, maxChar)}...` : description;
};

type BaseObjectValue = {
  id?: string;
  name?: string;
};

export const getValueFromStringOrObject = (
  value: string | string[] | BaseObjectValue[] | BaseObjectValue
): string | string[] => {
  // check array type
  if (Array.isArray(value)) {
    if (value.length === 0) return [];
    return value?.map((o) => (typeof o === "object" ? o?.id ?? o?.name ?? "" : o));
  }
  switch (typeof value) {
    case "string":
      return value;
    case "object":
      return value?.id ?? value?.name ?? "";
    default:
      throw new Error("Failed to set value type");
  }
};

export const formatSizeFromBytes = (bytes: number): string => {
  if (bytes > 10000) {
    return `${(bytes / 1000000).toFixed(2)} MB`;
  }
  if (bytes > 1000) {
    return `${(bytes / 1000).toFixed(2)} KB`;
  }
  return `${bytes} B`;
};

export const ObjectTypeList: {
  key: string;
  name: string;
  description: string;
}[] = [
  {
    key: "Date",
    name: "Date Field",
    description: "For important dates",
  },
  {
    key: "Number",
    name: "Number Field",
    description: "For numeric values",
  },
  {
    key: "String",
    name: "Text Field",
    description: "For plain text",
  },
  {
    key: "Hyperlink",
    name: "Hyperlink",
    description: "Hyperlink to external resources",
  },
  {
    key: "JSON",
    name: "JSON Field",
    description: "For free form JSON",
  },
  {
    key: "User",
    name: "User Field",
    description: "For referencing a user",
  },
  {
    key: "Asset",
    name: "Asset Field",
    description: "For referencing an asset",
  },
  {
    key: "Number with Unit",
    name: "Number with Unit",
    description: "For numeric values with units",
  },
  {
    key: "User list",
    name: "User List",
    description: "For referencing multiple users",
  },
  {
    key: "Asset list",
    name: "Asset List",
    description: "For referencing multiple assets",
  },
  {
    key: "Registry Item list",
    name: "Registry Item List",
    description: "For referencing multiple registry items",
  },
  {
    key: "Registry Item",
    name: "Registry Item",
    description: "For referencing a registry item",
  },
  {
    key: "Rating Level",
    name: "Rating Level",
    description: "For rating level",
  },
  {
    key: "Monetary Value",
    name: "Monetary Value",
    description: "For Monetary Value",
  },
  {
    key: "Boolean",
    name: "Boolean",
    description: "For Boolean values",
  },
  {
    key: "Percentage",
    name: "Percentage",
    description: "For percentage",
  },
  {
    key: "MLFlow Model",
    name: "MLFlow Model",
    description: "For referncing a MLFlow Model",
  },
  {
    key: "Test Run",
    name: "Test Run",
    description: "For referncing a Test Run",
  },
  {
    key: "Test Case",
    name: "Test Case",
    description: "For referncing a Test Case",
  },
  {
    key: "Metric",
    name: "Metric",
    description: "For referncing a Metric",
  },
  {
    key: "Role",
    name: "Role",
    description: "For referncing a Role",
  },
  {
    key: "Workflow",
    name: "Workflow",
    description: "For referncing a Workflow",
  },
  {
    key: "Process Graph",
    name: "Process Graph",
    description: "For referncing a Process Graph",
  },
  {
    key: "Action",
    name: "Action",
    description: "For referncing a Action",
  },
  {
    key: "Test Run list",
    name: "Test Run List",
    description: "For referncing multiple Test Runs",
  },
  {
    key: "Test Case list",
    name: "Test Case List",
    description: "For referncing multiple Test Cases",
  },
  {
    key: "Metric list",
    name: "Metric List",
    description: "For referncing multiple Metrics",
  },
  {
    key: "Role list",
    name: "Role List",
    description: "For referncing multiple Roles",
  },
  {
    key: "Workflow list",
    name: "Workflow List",
    description: "For referncing multiple Workflows",
  },
  {
    key: "Process Graph list",
    name: "Process Graph List",
    description: "For referncing multiple Process Graphs",
  },
  {
    key: "Action list",
    name: "Action List",
    description: "For referncing multiple Actions",
  },
  { key: "Policy", name: "Policy", description: "For referencing a Policy" },
  { key: "Policy list", name: "Policy List", description: "For referencing multiple Policies" },
  { key: "Task", name: "Task", description: "For referencing a Task" },
  { key: "Task list", name: "Task List", description: "For referencing multiple Tasks" },
  { key: "Reference", name: "Reference", description: "For referencing a Reference" },
  {
    key: "Reference list",
    name: "Reference List",
    description: "For referencing multiple References",
  },
  { key: "Integration", name: "Integration", description: "For referencing a Integration" },
  {
    key: "Integration list",
    name: "Integration List",
    description: "For referencing multiple Integrations",
  },
  { key: "Resource", name: "Resource", description: "For referencing a Resource" },
  {
    key: "Resource list",
    name: "Resource List",
    description: "For referencing multiple Resources",
  },
  { key: "Parameter", name: "Parameter", description: "For referencing a Parameter" },
  {
    key: "Parameter list",
    name: "Parameter List",
    description: "For referencing multiple Parameters",
  },
  { key: "Registry", name: "Registry", description: "For referencing a Registry" },
  {
    key: "Registry list",
    name: "Registry List",
    description: "For referencing multiple Registries",
  },
  { key: "Framework", name: "Framework", description: "For referencing a Framework" },
  {
    key: "Framework list",
    name: "Framework List",
    description: "For referencing multiple Frameworks",
  },
  { key: "Element", name: "Element", description: "For referencing a Element" },
  { key: "Element list", name: "Element List", description: "For referencing multiple Elements" },
  { key: "Evidence", name: "Evidence", description: "For referencing a Evidence" },
  {
    key: "Evidence list",
    name: "Evidence List",
    description: "For referencing multiple Evidences",
  },
  {
    key: "Image",
    name: "Image",
    description: "For referencing an Image",
  },
  { key: "Department", name: "Department", description: "For referencing a Department" },
  {
    key: "Department list",
    name: "Department List",
    description: "For referencing multiple Departments",
  },
];

export const EvidenceOperators: { key: RuleLogicOperator; label: string }[] = [
  { key: "!=", label: "!= (operator.ne)" },
  { key: "==", label: "== (operator.eq)" },
  { key: ">", label: "> (operator.gt)" },
  { key: "<", label: "< (operator.lt)" },
  { key: "<=", label: "<= (operator.le)" },
  { key: ">=", label: ">= (operator.ge)" },
  { key: "count", label: "Count" },
  { key: "begins with", label: "Begins With" },
  { key: "ends with", label: "Ends With" },
  { key: "contains", label: "Contains" },
  { key: "is null", label: "Is Null" },
  { key: "is not null", label: "Is Not Null" },
  { key: "does not begin with", label: "Does Not Begin With" },
  { key: "does not end with", label: "Does Not End With" },
  { key: "does not contain", label: "Does Not Contain" },
];

export const EvidenceCustomNestedFields: {
  fieldName: string;
  nestedFields: { key: string; list: boolean }[];
}[] = [
  {
    fieldName: "authorized_users",
    nestedFields: [
      { key: "authorized_users:id", list: true },
      { key: "authorized_users:first_name", list: true },
      { key: "authorized_users:last_name", list: true },
      { key: "authorized_users:email", list: true },
    ],
  },
];

export const CommonEvidenceNestedFields: { key: string; list: boolean }[] = [
  { key: "tags:id", list: true },
  { key: "tags:name", list: true },
  { key: "tags:color", list: true },
  { key: "custom_fields:id", list: true },
  { key: "custom_fields:field_type", list: true },
  { key: "custom_fields:value", list: true },
  { key: "file_attachments:id", list: true },
  { key: "file_attachments:filename", list: true },
  { key: "file_attachments:filesize", list: true },
  { key: "mapped_elements:id", list: true },
  { key: "mapped_elements:relationship_name", list: true },
  { key: "mapped_elements:mapping_type", list: true },
  { key: "mapped_elements:to_object", list: true },
  { key: "mapped_elements:to_object_type", list: true },
  { key: "mapped_elements:additional_details", list: true },
];

export const isJSON = (str: string | object) => {
  try {
    typeof str === "string" && JSON.parse(str);
    typeof str === "object" && JSON.stringify(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const getIcon = (str: string) => {
  switch (str) {
    case "Workflow":
    case "ProcessGraph":
    case "ProcessNode":
    case "ProcessExecutionPlan":
    case "ProcessExecutionItem":
      return "workflow";
    case "TestCase":
    case "TestRun":
    case "TestResult":
      return "test";
    case "Metric":
    case "Registry":
    case "MetricResult":
      return "metrics";
    case "Action":
      return "action";
    case "Role":
      return "role";
    case "Asset":
      return "asset";
    case "ComplianceFramework":
    case "ComplianceElement":
    case "EvidenceRequirement":
      return "compliance";
    case "Resource":
      return "resources";
    default:
      return "organization";
  }
};

export const getFieldTypeToString = (fieldType: RegistryFieldType[]) => {
  try {
    return fieldType.join(", ");
  } catch (e) {
    if (typeof fieldType === "string") {
      return fieldType;
    }
    return "Failed to parse field type";
  }
};

export const getFeaturePaywallDetails = (route: string) => {
  switch (route) {
    case "workflows":
      return {
        icon: "workflows-paywall",
        title: "Workflows",
        description:
          "Manage complex workflows and processes with ease. Facilitate compliance through process execution and documentation.",
      };
    case "tests":
      return {
        icon: "tests-paywall",
        title: "Test Suite",
        description:
          "Centrally manage all of your metrics, test cases, test results, and test runs.",
      };
    case "assets":
      return {
        icon: "assets-paywall",
        title: "Assets",
        description: "Track and document your Assets, including policies, datasets, and more.",
      };
    case "resources":
      return {
        icon: "resources-paywall",
        title: "Resources",
        description: "Automated metadata discovery for datasets, models, policies, and more.",
      };
    case "mlflow":
      return {
        icon: "mlflow-paywall",
        title: "MLFlow",
        description:
          "Fully managed, scalable, and secure MLFlow. Fairo provides the only fully managed MLFlow solution designed for responsible AI.",
      };
    case "policies":
      return {
        icon: "policies-paywall",
        title: "Policies",
        description:
          "Create declarative policies and permissions modeled after AWS IAM. Leverage rules-based permissions across Fairo and compatible plugins.",
      };
    case "frameworks":
    case "library":
      return {
        icon: "compliance-paywall",
        title: "Compliance",
        description: "Enterprise compliance system for AI regulations and governance frameworks.",
      };
    case "impact-assessment-types":
      return {
        icon: "impact-assessment-loading",
        title: "Impact Assessment Types",
        description:
          "Centrally manage all of your metrics, test cases, test results, and test runs. Ensure that your AI solutions meet your standards for quality, risk management, and responsible AI.",
      };
    default:
      return { icon: "", title: "", description: "" };
  }
};

export const isValidUUID = (uuid: string) => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
};

export function capitalizeFirstLetter(value: string) {
  if (!value) return value;
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function uniqueTypeAndNumber(items: SemanticSimiliarityItem[]): SemanticSimiliarityItem[] {
  const uniqueItems = new Map<string, SemanticSimiliarityItem>();

  items.forEach((item) => {
    const key = `${item.type}-${item.number}`;
    if (!uniqueItems.has(key)) {
      uniqueItems.set(key, item);
    }
  });

  return Array.from(uniqueItems.values());
}

export const getTaskDescription = (task: ExecutionTask) => {
  const task_name = task?.parent_object?.name
    ? task?.parent_object?.name.replace("Microsoft RAI Impact Assessment: ", "")
    : task?.parent_object?.name;
  switch (task.task_type) {
    case "Answer Question":
      return `Answer ${task_name}`;
    case "Section Review":
      return `Review ${task_name}`;
    case "Question Review":
      return `Answer ${task_name}`;
    default:
      return task_name ?? `Task-${task.task_num}`;
  }
};

export const getTaskTypeDescription = (task: ExecutionTask) => {
  switch (task.task_type) {
    case "Answer Question":
      return `Answer Question`;
    case "Section Review":
      return `Section Review`;
    case "Question Review":
      return `Question Review`;
    default:
      return task.task_type;
  }
};

export const isListTypeQuestion = (fieldType: RegistryFieldType) => {
  return fieldType === "Name and Description list" || fieldType === "External Link";
};

export const showPaddingBetweenQuestionAndNavigation = (fieldType: RegistryFieldType) => {
  const fieldTypes: RegistryFieldType[] = [
    "Name and Description list",
    "External Link",
    "Text Area",
    "Languages",
    "Geographic Areas",
  ];
  return !fieldTypes.some((type) => type === fieldType);
};

export const getNextRecurrenceDate = (startDate: Date, frequencyInDays: number) => {
  const currentDate = moment();
  if (startDate) {
    let nextDate = moment(startDate);

    while (nextDate.diff(currentDate, "days") < 0) {
      nextDate = moment(nextDate).add(frequencyInDays, "days");
    }

    return nextDate.toISOString();
  }
};

export const downloadFile = (url: string, fileName: string): void => {
  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.blob();
    })
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = fileName;

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      window.URL.revokeObjectURL(blobUrl);
    })
    .catch((error) => {
      console.error("Error downloading the file:", error);
    });
};

export const getCursor = (urlString: string) => {
  const url = new URL(urlString);

  const searchParams = new URLSearchParams(url.search);

  const queryParamsList: [string, string][] = [];

  const cursorValue = searchParams.get("cursor[]");

  return cursorValue;
};

export function validateURL(url: string): boolean {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !!urlPattern.test(url);
}

export function validateEmail(email: string): boolean {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
}

export function validatePhoneNumber(phone: string): boolean {
  // const phonePattern = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  // return phonePattern.test(phone);
  return true;
}
export const assetTypeRestrictedNames = [
  "Model",
  "Dataset",
  "Policy",
  "Documentation",
  "Algorithm",
];

export const hasCreatedBy = (option: any): option is { created_by: PartialUser } => {
  return "created_by" in option;
};

export const customFieldsTemplate = [
  {
    key: "",
    type: "",
    value: "",
  },
];

export const questionTemplate = [
  {
    title: "",
    description: "",
    type: "",
    button_label: "",
    required: false,
    choices: [
      {
        value: "",
        label: "",
      },
    ],
    sub_section: "",
  },
];

export const questionnairesTemplate = [
  {
    name: "",
    status: "KICKOFF",
    custom_details: customFieldsTemplate,
    questions: questionTemplate,
  },
];

export const impactAssessmentQuestionnaireTemplate = [
  {
    name: "",
    description: "",
    section_num: 1,
    status: "KICKOFF",
    custom_details: customFieldsTemplate,
    questionnaires: questionnairesTemplate,
  },
];

export const hasResourceTypeName = (option: any): option is { resource_type_name: string } => {
  return "resource_type_name" in option;
};

export const fairoSettingsTemplate = [
  {
    module: "",
    methods: ["LIST", "CREATE", "RETRIEVE", "UPDATE", "DELETE"],
    objects: [],
  },
];
