import { INavbarIconVariant } from "../atoms/navbar/Icon";
import { StatusOption } from "../atoms/StatusBadge";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { LinkedItem } from "./IWorkflow";

export type Workflow = {
  id: string;
  summary: string;
  description?: string;
  workflow_num: number;
  created_by: PartialUser;
  updated_by: PartialUser;
  file_attachments?: FileAttachment[];
  custom_fields?: CustomFieldValue[];
  created_on: string;
  updated_on: string;
  status: WorkflowRunStatus;
  assignee: string;
  metadata?: any;
  process_graph: WorkflowProcessGraph;
  last_run: null | WorkflowRun;
  template_name?: PartialUser;
};

export type ActionType = "External-Github" | "External-Jira" | "Approval" | "Review";

export type ExecutionTaskStatus = "PENDING" | "COMPLETED" | "CANCELED";

export type ExternalTask = {
  id: string;
  key: string;
  summary?: string;
  issue_type?: string;
};

export type ExecutionTask = {
  name: string;
  action: string;
  action_num: number;
  action_type: ActionType;
  comments: string | null;
  completed_date: string | null;
  created_by: string;
  created_on: string;
  custom_fields: { [key: string]: any } | null;
  due_date: string | null;
  id: string;
  organization: string;
  parent_object_id: string;
  parent_type: RegistryFieldType;
  parent_object: {
    value: number;
    name?: string;
    roles?: string[];
  };
  task_type: TaskType;
  task_num: number;
  status: ExecutionTaskStatus;
  updated_by: PartialUser;
  updated_on: string;
  linked_items: LinkedItem[];
  assignee: string;
  assignee_name: string;
  integration_type?: PluginType;
  task_external_ids: ExternalTask[] | null;
  metadata?: {
    impact_assessment?: string;
    question_ref?: string;
  };
};

export type User = {
  id: string;
  username: string;
  email: string;
  name: string;
  permissions: string;
  is_invited: boolean;
  is_active: boolean;
  invited_on: string | null;
  date_joined: string | null;
  user_hash?: string;
  phone_number_confirmed?: boolean;
  type?: string;
  primary_auth_method?: string;
  require_2fa?: boolean;
  two_factor_auth_method?: string;
  phone_number?: string;
  first_name?: string;
  last_name?: string;
  job_title?: string;
  department?: string;
  department_name?: PartialUser;
  department_color?: string;
  manager?: string;
  manager_name?: PartialUser;
  roles?: PartialUser[];
  organization?: string;
  is_fairo_admin?: boolean;
};

export type Department = {
  id: string;
  name: string;
  color: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
  members_count: number;
};

// subset of `User` with fewer fields used to return info about attached user(eg. assignee, creator)
export type PartialUser = {
  id: string;
  name: string;
};

export type TestCase = {
  id: string;
  name: string;
  description?: string;
  required_parameters: Parameter[];
  optional_parameters: Parameter[];
  value_type: TestResultFieldType;
  operator: string | null;
  test_value: string | null;
  associated_metric: string | null;
  custom_fields?: CustomFieldValue[];
  file_attachments?: FileAttachment[];
  test_case_num: number;
  preconditions?: string;
  steps?: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
  test_results?: Pick<TestResult, "id" | "test_case" | "value" | "status" | "created_on">[];
  required_parameters_name?: PartialParameter[];
  optional_parameters_name?: PartialParameter[];
  parameter_values?: {
    id: string;
    value: string;
    field_type: RegistryFieldType;
    parameter: PartialParameter;
  }[];
  references?: string[];
};

export type Action = FairoBaseClass & {
  id: string;
  name: string;
  description?: string;
  custom_fields?: CustomFieldValue[];
  file_attachments?: FileAttachment[];
  action_num: number;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
  action_type: ActionType;
};

export type CommonStatus =
  | "Not-Started"
  | "In-Progress"
  | "Done"
  | "Draft"
  | "No Policy"
  | "Needs Approval"
  | "Approved"
  | "Action Required"
  | "Cancelled";

export type AssetStatusOptions =
  | "PENDING"
  | "NO POLICY"
  | "NO DOCUMENT"
  | "DRAFT"
  | "NEEDS APPROVAL"
  | "ACTION REQUIRED"
  | "APPROVED"
  | "EXCLUDED"
  | "NEW VERSION REQUIRED";

export type ComplianceElementTypeOptions =
  | "Control"
  | "Action"
  | "Framework Element"
  | "Legislation";

export type AssetSourceOptions = "FAIRO" | "MLFLOW" | "DATABRICKS";

export type Asset = {
  id: string;
  asset_num: number;
  name: string;
  description?: string;
  // reference to AssetType.id
  type: string;
  type_name: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  custom_fields?: CustomFieldValue[];
  file_attachments?: FileAttachment[];
  created_on: string;
  status: AssetStatusOptions;
  assignee?: string;
  metadata?: {
    submitted_by?: PartialUser;
    approver?: PartialUser;
  } & any;
  updated_on: string;
  assignee_name: string | null;
  recurrence: number;
  test_status: EvidenceTestStatusOptions;
  frameworks: PartialUser[];
  test_id: string;
  lifecycle_stage: string | null;
  template_version_id?: string | null;
  source: AssetSourceOptions | null;
  model_type?: string;
  tests?: {
    total: number;
    completed: number;
  };
};

export type AssetType = {
  id: string;
  name: string;
  description: string;
  created_by?: PartialUser;
  updated_by?: PartialUser;
};

export type Role = {
  id: string;
  name: string;
  description?: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  role_num: number;
  created_on: string;
  updated_on: string;
  file_attachments?: FileAttachment[];
  custom_fields?: CustomFieldValue[];
  authorized_users?: {
    id: string;
    name: string;
    email: string;
    invited_on: string;
    date_joined: string;
    is_active: boolean;
    is_invited: boolean;
    username: string;
    permissions: string;
  }[];
};

export type CustomFieldType =
  | "User"
  | "Date"
  | "JSON"
  | "Number"
  | "String"
  | "Asset"
  | "Tag List"
  | "Formatted Text";

export type CustomField = {
  id: string;
  field_name: string;
  description: string;
  field_type: CustomFieldType;
  field_key: string;
  created_by?: string;
  updated_by?: string;
  created_on?: string;
  updated_on?: string;
  placeholder?: string | null;
};

export type CustomFieldValue = {
  id: string;
  field_id: string;
  field_name: string;
  description: string;
  field_created_by?: string;
  field_key: string;
} & (
  | { field_type: "String"; value: string }
  | { field_type: "JSON"; value: string }
  | { field_type: "Date"; value: string }
  | { field_type: "User"; value: string }
  | { field_type: "Number"; value: number }
  | { field_type: "Asset"; value: string }
  | { field_type: "Tag"; value: string }
  | { field_type: "Tag List"; value: string }
  | { field_type: "Formatted Text"; value: string }
);

export type FileAttachment = {
  id: string;
  file_id: string;
  filename: string;
  file_object: string;
  file_relative_url: string;
  mimetype?: string;
  name?: string;
  description?: string;
  created_by?: PartialUser;
  created_on?: string;
  updated_by?: PartialUser;
  updated_on?: string;
  filesize?: string;
};

export type APIGetSummary<P extends APIFetchOptions> = (params: P) => {
  data?: ObjectSummary;
  isLoading: boolean;
  refetch?: () => void;
};

export type APIGetListHook<T extends { id: string }, P extends APIFetchOptions> = (params: P) => {
  data?: ListResponse<T>;
  isLoading: boolean;
  refetch?: () => void;
};

export type APIGetObjectHook<T extends { id: string }> = (
  id: string,
  params?: any
) => {
  data?: T;
  isLoading: boolean;
};

export type APIFetchOptions = {
  page?: number;
  page_size?: number;
  search?: string;
};

export type APIFetchOptionsFromFilters<T, F extends string> = APIFetchOptions &
  T &
  Partial<Record<F, string[]>>;

export type TestRunFetchOptions = APIFetchOptionsFromFilters<
  { test_run?: string; status?: string },
  BaseFilters
>;

export type TestCaseFetchOptions = APIFetchOptionsFromFilters<
  { node?: string; test_run?: string; deleted?: boolean },
  BaseFilters
>;
export type MetricFetchOptions = APIFetchOptionsFromFilters<
  { category?: string; fairo_data?: boolean },
  BaseFilters
>;
export type MetricResultFetchOptions = APIFetchOptionsFromFilters<
  {
    "created_on_from[]"?: string;
    "created_on_to[]"?: string;
    deleted?: boolean;
    "parameter_value[]"?: string;
    "metric[]"?: string[];
    return_empty?: boolean;
  },
  BaseFilters
>;
export type MetricCategoryFetchOptions = APIFetchOptionsFromFilters<
  { fairo_data?: boolean; deleted?: boolean },
  BaseFilters
>;

export type TestResultFilters = BaseFilters | "status";

export type TestResultFetchOptions = APIFetchOptionsFromFilters<
  {
    "created_on_from[]"?: string;
    "created_on_to[]"?: string;
    test_run?: string;
    test_case?: string;
    ordering?: "created_on" | "-created_on" | "updated_on" | "-updated_on";
    deleted?: boolean;
    "parameter_value[]"?: string;
  },
  TestResultFilters
>;

export type AssetFetchOptions = APIFetchOptionsFromFilters<
  {
    "test_status[]"?: EvidenceTestStatusOptions[];
    node?: string;
    deleted?: boolean;
    "asset_type_name[]"?: string[];
    "object_relates_to_id[]"?: string[];
    "exclude_asset_type_name[]"?: string[];
    "asset_framework[]"?: string[];
  },
  BaseFilters
>;

export type RoleFetchOptions = APIFetchOptionsFromFilters<
  { action?: string; deleted?: boolean; "object_relates_to_id[]"?: string[] },
  BaseFilters
>;

export type ActionFetchOptions = APIFetchOptionsFromFilters<
  { node?: string; deleted?: boolean; "object_relates_to_id[]"?: string[] },
  BaseFilters
>;

export type WorkflowFetchOptions = APIFetchOptionsFromFilters<
  {
    "object_relates_to_id[]"?: string[];
    "status[]"?: WorkflowRunStatus[];
  },
  BaseFilters
>;

export type ExecutionTaskFetchOptions = APIFetchOptionsFromFilters<
  {
    action?: string;
  } & {
    "parent_object_id[]"?: string[];
    "parent_type[]"?: RegistryFieldType[];
    "metadata_roles[]"?: string[];
    "metadata_impact_assessment[]"?: string[];
    "metadata_question_ref[]"?: string[];
    "assignee[]"?: string[];
    status?: ExecutionTaskStatus;
    "status[]"?: ExecutionTaskStatus[];
    deleted?: boolean;
    "task_asset_framework[]"?: string[];
    "task_asset_type_name[]"?: string[];
    "task_relates_to_use_case[]"?: string[];
    "task_framework[]"?: string[];
    "task_evidence_framework[]"?: string[];
    task_type?: TaskType[];
  },
  BaseFilters
>;

export type UserFetchOptions = APIFetchOptionsFromFilters<
  {
    role?: string;
    is_active?: "True" | "False";
    is_invited?: "True" | "False";
    authorized_roles?: string;
    api_key?: boolean;
    deleted?: boolean;
    "object_relates_to_id[]"?: string[];
    fairo_data?: boolean;
    "user_relates_to_use_case[]"?: string[];
  },
  BaseFilters
>;

export type DepartmentFetchOptions = APIFetchOptionsFromFilters<
  {
    "object_relates_to_id[]"?: string[];
  },
  BaseFilters
>;

export type FilterOption<T> = {
  label: string;
  value: T;
};

export type QueryFilter<T> = {
  filterOption?: FilterOption<T>;
  value: string;
};

export type Organization = {
  id: string;
  name: string;
  description: string;
  billing_stripe_id: string;
  url: string | null;
  created_by: string | null;
  created_on: string | null;
  updated_by: string | null;
  updated_on: string | null;
  billing_name: string | null;
  billing_contact_name2: string | null;
  billing_address1: string | null;
  billing_address2: string | null;
  billing_city: string | null;
  billing_state: string | null;
  billing_email1: string | null;
  billing_email2: string | null;
  admins?: User[];
  members_count?: number;
  last_activity?: string;
  terms_conditions_accepted: boolean;
};

export type TestRun = {
  id: string;
  name: string;
  description: string;
  status: TestRunStatus;
  test_run_num: number;
  all_test_cases?: boolean;
  required_test_cases: TestCase[];
  created_by: PartialUser;
  created_on: string;
  updated_by: PartialUser;
  updated_on: string;
  test_results?: Pick<TestResult, "id" | "test_case" | "value" | "status">[];
};

export type TestResultStatus = "Passed" | "Failed" | "Untested" | "Blocked" | "Retest";

export type TestRunStatus = "COMPLETED" | "In-Progress" | "Not-Started";

export type TestResultFieldType = "Date" | "JSON" | "Number" | "String";

export type TestResult = {
  id: string;
  test_result_num: number;
  test_case: string | { id: string; name: string };
  value: string;
  value_type?: RegistryFieldType;
  status: TestResultStatus;
  assignee?: string;
  assignee_name?: string;
  related_metric_result?: string;
  test_run: string;
  parameter_values: {
    id: string;
    value: string;
    field_type: string;
    parameter: PartialParameter;
    value_name: string;
  }[];
  created_by: PartialUser;
  created_on: string;
  updated_by: PartialUser;
  updated_on: string;
};

export type Metric = {
  id: string;
  name: string;
  description?: string;
  metric_num: number;
  category?: string;
  category_name?: { id: string; name: string; created_by: PartialUser };
  references?: string[];
  required_parameters?: string[];
  optional_parameters?: string[];
  required_parameters_name?: PartialParameter[];
  optional_parameters_name?: PartialParameter[];
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
  file_attachments?: FileAttachment[];
  custom_fields?: CustomFieldValue[];
};

export type MetricResult = {
  id: string;
  custom_fields?: CustomFieldValue[];
  file_attachments?: FileAttachment[];
  created_by: PartialUser;
  updated_by: PartialUser;
  metric_result_num: number;
  value: string;
  created_on: string;
  updated_on: string;
  metric: string;
  parameter_values: {
    id: string;
    value: string;
    field_type: RegistryFieldType;
    value_name: string;
    parameter: PartialParameter;
  }[];
  assignee?: string;
  assignee_name?: string;
  metric_name: PartialUser | null;
};

export type MetricCategory = {
  id: string;
  name: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
};

export type Parameter = {
  id: string;
  name: string;
  field_type: RegistryFieldType[];
  description: string;
  created_by: PartialUser;
  created_on: string;
  updated_by: PartialUser;
  updated_on: string;
};

export type PartialParameter = {
  id: string;
  name: string;
  description: string;
  field_type: RegistryFieldType[];
  created_by: PartialUser;
};

export type ParameterValue = {
  id: string;
  value: string;
  related_object_id: string;
  field_type: string;
  created_on: string;
  updated_on: string;
  organization: string;
  parameter: string;
  created_by: PartialUser;
  updated_by: PartialUser;
};

export type ValueType = "String" | "Number" | "Date" | "JSON" | "Asset" | "User";

export type TestCaseAdvancedSettings = {
  requiredParameters: Parameter[];
  optionalParameters: Parameter[];
  valueType: ValueType;
  operator: string;
  testValue: string;
  metric: Metric;
};

export type ParameterFetchOptions = APIFetchOptionsFromFilters<
  {
    deleted?: boolean;
  },
  BaseFilters
>;

export type ReferenceType =
  | "Book"
  | "Conference Paper"
  | "Document"
  | "Encyclopedia Article"
  | "Journal Article"
  | "Manuscript"
  | "Patent"
  | "Report"
  | "Web Page";

// @todo setup reference data type
export type ReferenceData = {
  authorName?: string;
  authorLastName?: string;
  publisher?: string;
  date?: string;
  url?: string;
  accessed?: string;
  shortTitle?: string;
  language?: string;
  place?: string;
  pages?: string;
  archive?: string;
  libraryCatalog?: string;
  volume?: string;
  volumes?: string;
  originalDate?: string;
  edition?: string;
  isbn?: string;
  callNumber?: string;
  abstract?: string;
  proceedingsTitle?: string;
  conferenceName?: string;
  series?: string;
  doi?: string;
  encyclopediaTitle?: string;
  issue?: string;
  seriesTitle?: string;
  seriesText?: string;
  journalAbbr?: string;
  issn?: string;
  inventor?: string;
  inventorLastName?: string;
  patentNumber?: string;
  fillingDate?: string;
  priorityNumbers?: string;
  issueDate?: string;
  assignee?: string;
  issuingAuthority?: string;
  country?: string;
  applicationNumber?: string;
  references?: string;
  legalStatus?: string;
  reportName?: string;
  institution?: string;
  websiteTitle?: string;
  websiteType?: string;
  publication?: string;
  type?: string;
};

export type ReferenceFetchOptions = APIFetchOptionsFromFilters<
  {
    fairo_data?: boolean;
    deleted?: boolean;
  },
  BaseFilters
>;

export type Reference = {
  id: string;
  reference_num: number;
  title?: string;
  year?: number;
  reference_type: ReferenceType;
  author?: string;
  data?: ReferenceData;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
};

export type RegistryFieldType =
  | "Date"
  | "Number"
  | "String"
  | "JSON"
  | "Asset"
  | "User"
  | "Number with Unit"
  | "User list"
  | "Asset list"
  | "Rating Level"
  | "Monetary Value"
  | "Boolean"
  | "Percentage"
  | "MLFlow Model"
  | "Test Run"
  | "Test Case"
  | "Metric"
  | "Role"
  | "Workflow"
  | "Action"
  | "Test Run list"
  | "Test Case list"
  | "Metric list"
  | "Role list"
  | "Workflow list"
  | "Action list"
  | "Policy"
  | "Policy list"
  | "Task"
  | "Task list"
  | "Reference"
  | "Reference list"
  | "Integration"
  | "Integration list"
  | "Resource"
  | "Resource list"
  | "Parameter"
  | "Parameter list"
  | "Framework"
  | "Framework list"
  | "Element"
  | "Element list"
  | "Evidence"
  | "Evidence list"
  | "Hyperlink"
  | "Image"
  | "Report"
  | "Questionnaire"
  | "MLFlow Data"
  | "Test Result"
  | "Metric Result"
  | "Department"
  | "Department list"
  | "Impact Assessment"
  | "Name and Description"
  | "Name and Description list"
  | "Languages"
  | "Geographic Areas"
  | "External Link"
  | "IA Stakeholder"
  | "Text Area"
  | "Yes or No"
  | "String list"
  | "Use Case"
  | "Use Case list"
  | "Risk Registry"
  | "Tag"
  | "Tag List"
  | "Formatted Text"
  | "Question"
  | "Vendor"
  | "Potential Harm"
  | "Jira Ticket"
  | "Confluence Page"
  | "Data Origin"
  | "Risk Level"
  | "Relationship Complexity"
  | "Single Selection"
  | "File or Link"
  | "Asset with Description list"
  | "Metric with Description list"
  | "Metric Result Test Result list"
  | "Dataset list"
  | "Model list"
  | "Vendor Procurement Requirement"
  | "Personal Information"
  | "Multiple Selection"
  | "Plugin Inference Table";

export type RelationshipComplexity = {
  expertise: number | null;
  causality: number | null;
  linearity: number | null;
  reducibility: number | null;
  solvability: number | null;
};

export type RegistryFieldStyleRule = {
  operator: RuleLogicOperator;
  value: string;
  color: string;
};

export type PartialRegistry = {
  id: string;
  name: string;
  registry_type: string;
  type_name: {
    id: string;
    name: string;
  };
};

export type PartialRegistryField = {
  id: string;
  name: string;
};

export type ElementMappingType =
  | "Policy to Control"
  | "Control to Use Case"
  | "Action to Use Case"
  | "Documentation to Control"
  | "Evidence to Control"
  | "Action to Control"
  | "Evidence to Action"
  | "Framework to Use Case"
  | "Resource to Use Case"
  | "User to Use Case"
  | "Model to Use Case"
  | "Dataset to Use Case"
  | "Control to Risk Registry"
  | "Workflow to Control"
  | "Vendor to Use Case"
  | "Vendor to Impact Assessment"
  | "Documentation to Use Case"
  | "Policy to Use Case"
  | "Workflow to Use Case"
  | "Role to Use Case"
  | "Assessment to Use Case"
  | "Resource to Dataset"
  | "Element Mapping"; // @Depracated

export type ElementMappingRelationshipName =
  | "Applies to"
  | "Covers"
  | "Relates to"
  | "Owns"
  | "Requires"
  | "Belongs to"
  | "Element of";

export type SupportedElementMappingModels =
  | "workflows.Workflow"
  | "users.Action"
  | "users.User"
  | "users.Policy"
  | "assets.Asset"
  | "users.Role"
  | "users.Department"
  | "tests.TestCase"
  | "tests.TestRun"
  | "tests.TestResult"
  | "tests.Metric"
  | "tests.MetricResult"
  | "compliance.ComplianceFramework"
  | "compliance.ComplianceElement"
  | "compliance.Questionnaire"
  | "compliance.EvidenceRequirement"
  | "compliance.UseCase"
  | "compliance.ImpactAssessment"
  | "central.Resource"
  | "compliance.RiskRegistry"
  | "compliance.EvidenceTest"
  | "compliance.Vendor";

export type ElementMapping = {
  id: string;
  mapping_type: ElementMappingType;
  relationship_name: ElementMappingRelationshipName;
  from_object: string;
  from_object_name?: string;
  from_object_type: SupportedElementMappingModels;
  to_object: string;
  to_object_name?: string;
  to_object_type: SupportedElementMappingModels;
  additional_details: string;
  created_by: PartialUser;
  created_on: string;
  updated_by: PartialUser;
  updated_on: string;
};

export type ElementMappingFilters = BaseFilters | "from_object" | "to_object";
export type ElementMappingFetchOptions = APIFetchOptionsFromFilters<
  {
    fairo_data?: boolean;
    from_object_name?: boolean;
    to_object_name?: boolean;
    deleted?: boolean;
    exclude_mapping_type?: ElementMappingType[];
    "object_relates_to_id[]"?: string[];
  },
  ElementMappingFilters
>;

export type ComplianceElementDetail = {
  id: string;
  assignee: string;
  compliance_element: string;
  assignee_name: PartialUser;
  created_on: string;
  updated_on: string;
  created_by: PartialUser;
  updated_by: PartialUser;
};

export type Progress = {
  completed: number;
  total: number;
};

export type ComplianceElement = {
  id: string;
  name: string;
  framework: string;
  parent: string | null;
  external_id: string;
  element_num: number;
  element_type: ComplianceElementTypeOptions;
  description?: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  framework_name: PartialUser;
  created_on: string;
  updated_on: string;
  details?: ComplianceElementDetail;
  organization?: string;
  file_attachments?: FileAttachment[];
  custom_fields?: CustomFieldValue[];
  mapped_to?: string[];
  assignee: string;
  status: string;
  assignee_name: string | null;
  common_data_updated: {
    updated_on: string;
    updated_by: PartialUser;
  };
  evidences_progress?: Progress;
  documentations_progress?: Progress;
  policies_progress?: Progress;
  progress?: Progress;
  due_date?: string;
};

export type ComplianceElementFetchOptions = APIFetchOptionsFromFilters<
  {
    "framework_id[]"?: string;
    "element_type[]"?: ComplianceElementTypeOptions[] | string[];
    fairo_data?: boolean;
    element_type?: ComplianceElementTypeOptions | string;
    "object_relates_to_id[]"?: string[];
    framework?: string;
    "framework[]"?: string[];
    parent?: string | null;
    "parent[]"?: string[];
    "parent_is_null[]"?: boolean[];
    "status[]"?: string[];
    "control_relates_to_use_case[]"?: string[];
  },
  BaseFilters
>;

export type ComplianceElementType = {
  id: string;
  name: string;
  description?: string;
  element_type_num: number;
  organization: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
};
export type ComplianceElementTypeFetchOptions = APIFetchOptionsFromFilters<
  { fairo_data?: boolean; deleted?: boolean },
  BaseFilters
>;

export type ComplianceFrameworkFetchOptions = APIFetchOptionsFromFilters<
  { fairo_data?: boolean; deleted?: boolean; "object_relates_to_id[]"?: string[] },
  BaseFilters
>;

export type ComplianceFramework = {
  id: string;
  name: string;
  description?: string;
  framework_num: number;
  progress: { completed: number; total: number; started_on: string };
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
  file_attachments?: FileAttachment[];
  custom_fields?: CustomFieldValue[];
};

export type EvidenceFrequencyOptions = "DAILY" | "WEEKLY" | "MONTHLY" | "QUARTERLY" | "YEARLY";
export type EvidenceTestTypeOptions = "POLICY" | "DOCUMENTATION" | "TEST";
export type EvidenceTestScopeTypeOptions = "ALL" | "SPECIFIC";
export type EvidenceTestStatusOptions =
  | "N/A"
  | "PENDING"
  | "DUE SOON"
  | "OVERDUE"
  | "OK"
  | "DEACTIVATED";

export type EvidenceTestInstructionType = "CREATE_MONITOR" | null;
export type EvidenceTest = {
  id: string;
  name: string;
  description?: string;
  checklist_num: number;
  evidence_test_num: number;
  sla?: number;
  metadata?: any;
  evidence_logic?: DiscriminatedLogicObject;
  status: EvidenceTestStatusOptions;
  scope: RegistryFieldType;
  scope_type: EvidenceTestScopeTypeOptions;
  scope_filter: string;
  frequency: EvidenceFrequencyOptions;
  evidence_test_type: EvidenceTestTypeOptions;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
  assignee: string | null;
  assignee_name: string | null;
  common_data_updated?: {
    updated_on: string;
    updated_by: PartialUser;
  };
  evidence_instructions?: string[];
  frameworks: PartialUser[];
  scope_display: {
    info: string | null;
    value: string;
  };
  instructions_action: EvidenceTestInstructionType;
};

export type EvidenceTestInScopeResourceFetchOptions = APIFetchOptionsFromFilters<
  {
    "status[]"?: string[];
    "resource_type[]"?: RegistryFieldType[];
  },
  BaseFilters
>;

export type EvidenceTestInScopeResource = {
  id: string;
  name: string;
  resource_id: string;
  resource_type: RegistryFieldType;
  evidence_test: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
  status: EvidenceTestStatusOptions;
  resource_type_name: string;
  metadata: {
    sla_date?: string;
  };
};

export type EvidenceMappingFetchOptions = APIFetchOptionsFromFilters<
  {
    to_object?: string;
    fairo_data?: boolean;
    from_object_name?: boolean;
    to_object_name?: boolean;
    deleted?: boolean;
  },
  BaseFilters
>;

export type PartialRegistryItemValue = {
  registry_field: string;
  value: string | string[];
};

export type MLFlowModel = {
  name: string;
  creation_timestamp: Date;
  last_updated_timestamp: Date;
};

export type MLFlowModelResponse = {
  registered_models?: MLFlowModel[];
};

// Value is always a string. It is transformed to native type after sufficient error handling
export type FieldHandlerProps = {
  value: any;
  onChange: (newVal: any) => void;
  error: boolean;
  errorMessage: string;
  label?: string;
  required?: boolean;
  rows?: number;
  disabled?: boolean;
};

export type ICustomInputValueProps = {
  id: string;
  value: string | string[];
  error: boolean;
  errorMessage: string;
  field_type?: string;
};

export type TrialPlan = {
  price: string;
  feature_list: string;
};

export type PolicyFetchOptions = APIFetchOptionsFromFilters<
  {
    id?: string;
    fairo_data?: boolean;
    role?: string | string[];
    deleted?: boolean;
    "object_relates_to_id[]"?: string[];
  },
  BaseFilters
>;

export type Policy = {
  id: string;
  policy_num: number;
  name: string;
  description?: string;
  value: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
};

export type IAMPolicy = {
  action: string[];
  resources?: string[];
  effect: "allow" | "deny";
  principal: string[];
};

export type ApiKey = {
  id: string;
  external_id: string;
  description: string;
  secret: string;
};

export type AuditLog = {
  id: string;
  organization: string;
  status_code: number;
  status: string;
  request_id: string;
  method: string;
  action: string;
  path: string;
  object_id: string;
  origin: string;
  error_message: string;
  created_by: PartialUser;
  created_on: string;
  updated_by: PartialUser;
  updated_on: string;
};

export type AuditLogFilters = BaseFilters | "action" | "status";
export type AuditLogFetchOptions = APIFetchOptionsFromFilters<
  {
    cursor?: string;
  },
  AuditLogFilters
>;

export type VersionHistory = {
  id: string;
  organization: string;
  object_id: string;
  version_num: number;
  object: any;
  object_type: string;
  tag: {
    id: string;
    tag_id: string;
    name: string;
  };
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
};

export type VersionHistoryFetchOptions = APIFetchOptionsFromFilters<
  {
    "status[]"?: string[];
  },
  BaseFilters
>;

export type VersionTag = {
  id: string;
  organization: string;
  name: string;
  tag_id: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
};

export type VersionTagFetchOptions = APIFetchOptionsFromFilters<{}, BaseFilters>;

export type PluginAuth = {
  auth_type: "oauth" | "token_auth" | "boolean";
  fields?: any;
  auth_url?: string;
};
export type PluginType = "github" | "jira" | "aws" | "mlflow" | "openai" | "databricks";

export type PluginDataFetchOptions = APIFetchOptionsFromFilters<{}, BaseFilters>;

export type PluginData = {
  id: string;
  plugin_type: PluginType;
  plugin_display_name: string;
  plugin_logo_url: INavbarIconVariant;
  plugin_description: string;
  plugin_metadata: any;
  auth: PluginAuth[];
  status: string;
  guide_checklist: number;
  webhook: boolean;
  resource_discovery: boolean;
  minimum_plan?: string;
};

export type PluginDataObjectFetchOptions = APIFetchOptionsFromFilters<
  {
    parent_object_id?: string;
    parent_type?: string;
  },
  BaseFilters
>;

export type PluginDataObject = {
  id: string;
  external_id: string;
  type: PluginType;
  name: string;
  url: string;
  updated_on: string;
  created_on: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  size: number;
  plugin_data_num: number;
  parent_object_id: string;
  parent_type: RegistryFieldType;
};

export type RatingLevelOptionFetchOptions = APIFetchOptionsFromFilters<{}, BaseFilters>;
export type RatingLevelOption = {
  id: string;
  name: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
};

export type TagFetchOptions = APIFetchOptionsFromFilters<{ fairo_data?: boolean }, BaseFilters>;
export type Tag = {
  id: string;
  name: string;
  color: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
};

export type ObjectTagFetchOptions = APIFetchOptionsFromFilters<
  { fairo_data?: boolean; parent_object_id: string },
  BaseFilters
>;

export type ObjectTag = {
  id: string;
  tag: string;
  tag_name: Tag;
  parent_object_id: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
};

export type BaseFilters =
  | "id"
  | "name"
  | "test_case_num"
  | "created_by"
  | "updated_by"
  | "updated_on"
  | "metric_num"
  | "value"
  | "metric_result_num"
  | "metric"
  | "assignee_name"
  | "test_run_num"
  | "test_result_num"
  | "created_on"
  | "asset_num"
  | "role_num"
  | "action_num"
  | "workflow_num"
  | "description"
  | "summary"
  | "required_actions"
  | "required_test_cases"
  | "related_assets"
  | "collaborators_name"
  | "action_type"
  | "task_num"
  | "email"
  | "field_type"
  | "title"
  | "reference_num"
  | "from_object"
  | "to_object"
  | "relationship_name"
  | "mapping_type"
  | "from_object_type"
  | "to_object_type"
  | "relates_to"
  | "element_num"
  | "element_type"
  | "external_id"
  | "from_object_id"
  | "to_object_id"
  | "policy_num"
  | "status_code"
  | "path"
  | "object_id"
  | "version_num"
  | "tag"
  | "tag_null"
  | "tag_id"
  | "plugin_display_name"
  | "evidence_test_num"
  | "evidence_test"
  | "resource_num"
  | "resource"
  | "form_name"
  | "read"
  | "message"
  | "report_num"
  | "assignee"
  | "risk_scenario_num"
  | "use_case_num"
  | "audit_num"
  | "auditors"
  | "exclude_id"
  | "vendor_num"
  | "department";

export const BaseFilterOptions: FilterOption<BaseFilters>[] = [
  { label: "Id", value: "id" },
  { label: "Created By", value: "created_by" },
  { label: "Created On", value: "created_on" },
  { label: "Updated On", value: "updated_on" },
];

export type S3Bucket = {
  Name: string;
  CreationDate: string;
};

export type FairoBaseClass = {
  id: string;
  plugin_data?: S3Object[];
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
};

export type S3Object = {
  id: string;
  external_id: string;
  type: PluginType;
  name: string;
  url: string;
  updated_on: string;
  updated_by: PartialUser;
  size: number;
};

export type Resource = {
  id: string;
  resource_num: number;
  external_id: string;
  system: string;
  mapped_resource: string;
  mapped_resource_type: string;
  mapped_resource_name: string;
  status: string;
  last_sync: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
};

export type ConceptMapping = {
  id: string;
  concept: string;
  fairo_concept: string;
  fairo_concept_type: string;
  representation: string;
  fairo_representation: string;
  notes: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
};

export type AIClassification = {
  id: string;
  fairo_concept: string;
  fairo_representation: string;
  notes: string;
  status: string;
};

export type ConceptMappingFetchOptions = APIFetchOptionsFromFilters<{}, BaseFilters>;

export type ResourceDiscoveryData = {
  id: string;
  status: string;
  integrations: any;
  last_run: any;
};

export type EvidenceTestHistoryFetchOptions = APIFetchOptionsFromFilters<
  {
    fairo_data?: boolean;
    "evidence_resource[]"?: string[];
  },
  BaseFilters
>;

export type EvidenceTestHistory = {
  id: string;
  evidence_test: string;
  created_on: string;
  updated_on: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  status: "FAILED" | "PASSED";
  evidence_resource: string;
  tested_data: any;
  tested_logic: any;
  tested_response: any;
};

export type LogicObject = {
  type: "group" | "rule";
  value: GroupLogicObject | RuleLogicObject;
};

export type GroupLogicObject = {
  action: "and" | "or";
  rules: DiscriminatedLogicObject[];
};

export type RuleLogicOperator =
  | "!="
  | "=="
  | ">"
  | "<"
  | "<="
  | ">="
  | "count"
  | "begins with"
  | "ends with"
  | "contains"
  | "is null"
  | "is not null"
  | "does not begin with"
  | "does not end with"
  | "does not contain";

export type RuleLogicObject = {
  tests: {
    attribute: string;
    action: RuleLogicOperator;
    value: string;
  }[];
  object_list_type?: "all" | "any";
  field_list_type?: "all" | "any";
  object_id: string | string[];
  object_type: string;
};

// Add a discriminant property to LogicObject
export type DiscriminatedLogicObject =
  | (LogicObject & {
      type: "group";
      value: GroupLogicObject;
    })
  | {
      type: "rule";
      value: RuleLogicObject;
    };

export type EvidenceRequirementFetchOptions = APIFetchOptionsFromFilters<
  {
    fairo_data?: boolean;
    "object_relates_to_id[]"?: string[];
    "evidence_test_type[]"?: EvidenceTestTypeOptions[];
    "status[]"?: EvidenceTestStatusOptions[];
    "evidence_test_framework[]"?: string[];
  },
  BaseFilters
>;

export type WebhookMessageFetchOptions = APIFetchOptionsFromFilters<
  {
    "integration_type[]"?: PluginType;
  },
  BaseFilters
>;

export type WebhookMessage = {
  id: string;
  message_id: string;
  integration_type: PluginType;
  status: string;
  message?: {};
  created_on: string;
  updated_on: string;
  custom_fields: {};
};

export type AssignedToMe = {
  id: string;
  parent_object_id: string;
  type: RegistryFieldType;
  updated_on: string;
  updated_by: PartialUser;
  object: {
    internal_id: string;
    name: string;
  };
};

export type RecentViewed = {
  id: string;
  object: {
    name: string;
    internal_id: string;
    id: string;
    type: RegistryFieldType;
  };
  object_id: string;
  updated_on: string;
  updated_by: PartialUser;
};
export type TaskType =
  | "Compliance Task"
  | "Registry Task"
  | "Task"
  | "Onboard Task"
  | "Organization Setup Task"
  | "Use Case Setup Task"
  | "Approval"
  | "Review"
  | "Answer Question"
  | "Section Review"
  | "Question Review";

export type QuestionnaireFetchOptions = APIFetchOptionsFromFilters<
  {
    section?: string | string[];
    "metadata_section[]"?: number[];
    status?: string;
    "metadata_public[]"?: boolean[];
  },
  BaseFilters
>;

export type QuestionChoice = { label: string; value: string };

export type Question = {
  ref: string;
  type: RegistryFieldType;
  logic: null;
  title: string;
  choices: null | QuestionChoice[];
  required: boolean;
  description: string;
  button_label: string;
  metadata?: any;
};

export type QuestionnaireStatusOptions =
  | "KICKOFF"
  | "DISABLED"
  | "IN PROGRESS"
  | "UNDER REVIEW"
  | "FINALIZED";

export type QuestionnaireSectionMetadataDetails = {
  key: string;
  type: RegistryFieldType;
  value: string;
};

export type Questionnaire = {
  session_id?: string;
  redirect_url?: string;
  id: string;
  description?: string;
  form_id: string;
  questions: Question[];
  insights: TypeformFormInsight;
  last_completed_on: string;
  registries?: string[];
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
  metadata?: {
    roles?: string[];
    section?: number;
    details?: QuestionnaireSectionMetadataDetails[];
  };
  assignee: string | null;
  assignee_name: string | null;
  section: string;
  questionnaire_num: number;
  name: string;
  status: QuestionnaireStatusOptions;
  impact_assessment: string;
  section_name: PartialUser;
  custom_details?: QuestionnaireSectionMetadataDetails[];
};

export type TypeformForm = {
  total_items: number;
  page_count: number;
  items: TypeformFormItem[];
};

export type TypeformFormItem = {
  _links: {
    display: string;
    responses: string;
  };
  created_at: string;
  id: string;
  last_updated_at: string;
  self: {
    href: string;
  };
  settings: {
    is_public: boolean;
  };
  title: string;
};

export type TypeformFormInsight = {
  average_time: number;
  completion_rate: number;
  responses: number;
};

export type QuestionnaireResponses = {
  question_id: string;
  question_ref: string;
  question: string;
  question_description: string;
  total_responses: number;
  responses: QuestionnaireResponse[];
};

export type QuestionnaireResponse = {
  label: string;
  type: string;
  count: number;
  total: number;
  response_id: string;
  created_on: string;
  user_id: string;
  user_name: PartialUser;
};

export type Notification = {
  id: string;
  message: string;
  created_on: string;
  updated_on: string;
  organization: string;
  to_object_id: string;
  to_object_type: RegistryFieldType;
  read: boolean;
  to_user: string;
  value_name?: string;
};

export type NotificationFetchOptions = APIFetchOptionsFromFilters<
  {
    "read[]"?: "True" | "False";
  },
  BaseFilters
>;

export type ReportType = "Overview";

export type ReportFetchOptions = APIFetchOptionsFromFilters<{}, BaseFilters>;

export type Report = {
  id: string;
  name: string;
  created_on: string;
  report_num: string;
  report_type: ReportType;
  created_by: PartialUser;
  url: string;
};

export type CreateReportProps = {
  name: string;
  report_type: ReportType;
  users: string[];
  related_objects: {
    object_id: string;
    object_type: RegistryFieldType;
  }[];
};

export type AssignedToMeFetchOptions = APIFetchOptionsFromFilters<
  {
    userId?: string;
    type?: RegistryFieldType;
    object_relates_to_id?: string;
    parent_asset_type_name?: string[];
    "type[]"?: RegistryFieldType[];
    "exclude_type[]"?: RegistryFieldType[];
    "exclude_completed_tasks[]"?: boolean;
  },
  BaseFilters
>;

export type RecentViewedFetchOptions = {
  method?: string[];
  status?: string[];
  type?: string[];
  exclude_action?: string[];
  exclude_path?: string[];
  page?: number;
  page_size?: number;
  search?: string;
};

export type ImpactAssessmentType = {
  id: string;
  name: string;
  impact_assessment_type_num: number;
  description: string;
  minimum_plan: string;
  organization: string;
  questionnaire?: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
};

export type SectionStatus = "KICKOFF" | "IN PROGRESS" | "UNDER REVIEW" | "FINALIZED" | "DISABLED";
export type ImpactAssessmentSection = {
  id: string;
  section_num: number;
  name: string;
  status: SectionStatus;
};

export type ImpactAssessment = {
  id: string;
  name: string;
  impact_assessment_num: number;
  description?: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
  impact_assessment_type_name: { id: string; name: string };
  organization?: string;
  impact_assessment_type?: string;
  progress: {
    total: number;
    completed: number;
  };
  sections: QuestionnaireSection[];
  use_case: string;
  use_case_name: { id: string; name: string };
  vendor: string;
  vendor_name: { id: string; name: string; website?: string };
  status: ImpactAssessmentStatus;
  metadata?: {
    related_regulations?: SemanticSimiliarityItem[];
  };
  owner?: string | null;
  assessment_owner?: string | null;
  assessment_owner_name?: PartialUser | null;
  reviewers?: string[];
  reviewers_name?: PartialUser[];
  related_teams: string[];
  related_teams_names?: {
    id: string;
    name: string;
    color: string;
  }[];
  use_case_owner?: PartialUser;
};

export type ImpactAssessmentStatus = "KICKOFF" | "IN PROGRESS" | "UNDER REVIEW" | "FINALIZED";

export type IAExternalLink = {
  name: string;
  link: string;
};

export type IANameAndDescription = {
  name: string;
  description: string;
};

export type IAStakeholder = {
  user: string;
  benefits: string[];
  harms: string[];
};

export type QuestionnaireAnswerFetchOptions = APIFetchOptionsFromFilters<
  {
    questionnaire?: string | string[];
    question?: string | string[];
    status?: string | string[];
    "answer_impact_assessment[]"?: string[];
  },
  BaseFilters
>;

export type QuestionnaireAnswer = {
  id: string;
  field_type: RegistryFieldType;
  question: string;
  questionnaire: string;
  answer: string;
  status: "DRAFT" | "ARCHIVE" | "FINALIZED" | "PENDING REVIEW";
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
  question_details: Question;
};

export type SemanticSimiliarityItem = {
  blurb: string;
  type: string;
  number: number;
  link: string;
  intended_use: string;
};

export type ImpactAssessmentFetchOptions = APIFetchOptionsFromFilters<
  {
    use_case?: string;
    "object_relates_to_id[]"?: string[];
    "use_case_relates_to_id[]"?: string[];
    "use_case[]"?: string[];
    "status[]"?: ImpactAssessmentStatus[];
    "impact_assessment_type[]"?: string[];
    "assessment_owner[]"?: string[];
    "vendor[]"?: string[];
  },
  BaseFilters
>;

export type QuickLinkEventType =
  | "startTour"
  | "showArticle"
  | "showNews"
  | "startSurvey"
  | "startChecklist"
  | "Launch Impact Assessment"
  | "Launch Use Case"
  | "Launch Vendor"
  | "Navigate";

export type QuickLinkPage = "Home" | "Policy" | "Documentation";

export type IntercomEvent = {
  id: string;
  name: string;
  related_object_type: QuickLinkPage;
  event_type: QuickLinkEventType;
  value: string;
  completed: boolean;
};

export type IntercomEventFetchOptions = APIFetchOptionsFromFilters<
  {
    "event_type[]"?: QuickLinkEventType[];
    "related_object_type[]"?: QuickLinkPage[];
  },
  BaseFilters
>;

export type RiskCategoryFetchOptions = APIFetchOptionsFromFilters<{ name?: string }, BaseFilters>;

export type RiskCategory = {
  id: string;
  name: string;
  risk_category_num: number;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
};

export type RiskScenarioFetchOptions = APIFetchOptionsFromFilters<
  {
    name?: string;
    category?: string;
    "category[]"?: string[];
    "status[]"?: RiskScenarioStatusOption[];
  },
  BaseFilters
>;

export type RiskScenarioStatusOption = "NOT ADDED" | "NEEDS REMEDIATION" | "OK";

export type RiskScenario = {
  id: string;
  name: string;
  risk_scenario_num: number;
  category: string;
  description?: string;
  category_name: { id: string; name: string };
  risk_category_num: number;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
  status: RiskScenarioStatusOption;
  associated_use_cases: number;
};

export type UseCaseFetchOptions = APIFetchOptionsFromFilters<
  {
    "assignee[]"?: string[];
    "related_teams[]"?: string[];
    "status[]"?: UseCaseSatusOption[];
  },
  BaseFilters
>;
export type ImpactAssessmentTypeFetchOptions = APIFetchOptionsFromFilters<{}, BaseFilters>;

export type UseCase = {
  id: string;
  name: string;
  description: string;
  use_case_num: number;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
  related_teams: string[];
  related_teams_names: PartialUser[];
  assignee?: string;
  assignee_name?: string;
  potential_value: number | null;
  lifecycle_stage: string | null;
  progress?: {
    completed: number;
    total: number;
  };
  overall_risk: number | null;
  status: UseCaseSatusOption | null;
  frameworks: PartialUser[];
};

export type UseCaseSatusOption = "OK" | "OVERDUE" | "NEEDS REMEDIATION";

export type RiskLikelihoodOption = {
  score: number;
  name: RiskLikelihoodOptions;
};

export type PotentialValueLabelOptions =
  | "Minimal Value"
  | "Low Value"
  | "Modest Value"
  | "Fair Value"
  | "Moderate Value"
  | "Good Value"
  | "High Value"
  | "Very High Value"
  | "Exceptional Value"
  | "Maximum Value";

export type PotentialValueOption = {
  score: number;
  name: PotentialValueLabelOptions;
};

export type RiskImpactOption = {
  score: number;
  name: RiskImpactOptions;
};

export type RiskLikelihoodOptions =
  | "Very Unlikely"
  | "Unlikely"
  | "Somewhat Likely"
  | "Likely"
  | "Very Likely";

export type RiskImpactOptions = "Very Low" | "Low" | "Medium" | "High" | "Very High";

export type RiskMitigationOptions = "Mitigate" | "Transfer" | "Accept" | "Avoid";

export type RiskRegistryFetchOptions = APIFetchOptionsFromFilters<
  {
    risk_scenario?: string;
    use_case?: string;
    vendor?: string;
    owner?: string;
    "use_case[]"?: string[];
    "vendor[]"?: string[];
    "owner[]"?: string[];
    "status[]"?: string[];
    "vendor_is_not_null[]"?: boolean[];
    "use_case_is_not_null[]"?: boolean[];
    "assessment_response_is_not_null[]"?: boolean[];
  },
  BaseFilters
>;

export type RiskRegistryStatusOption = "NEEDS REMEDIATION" | "OK" | "PROVISIONAL RISK";

export type RiskEstablishedFromOption = "USE CASE" | "VENDOR" | "ASSESSMENT RESPONSE";

export type RiskRegistry = {
  id: string;
  risk_scenario: string;
  use_case: string;
  vendor: string;
  updated_on: string;
  created_on: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  description?: string;
  owner: string;
  inherent_risk_likelihood: number | null;
  inherent_risk_impact: number | null;
  residual_risk_likelihood: number | null;
  residual_risk_impact: number | null;
  risk_mitigation_strategy: RiskMitigationOptions | null;
  use_case_name: PartialUser | null;
  vendor_name: PartialUser | null;
  owner_name?: PartialUser;
  risk_scenario_name: PartialUser;
  category_name: string;
  progress?: Progress;
  status: RiskRegistryStatusOption;
  established_from: RiskEstablishedFromOption;
  assessment_response: string;
};

export type QuestionnaireSectionFetchOptions = APIFetchOptionsFromFilters<
  { impact_assessment: string | string[] },
  BaseFilters
>;

export type QuestionnaireSection = {
  id: string;
  name: string;
  description: string | null;
  updated_on: string;
  created_on: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  metadata: any;
  section_num: number;
  status: SectionStatus;
  questionnaires?: any;
};

export type ObjectSummary = {
  completed: number;
  total: number;
  summary: {
    status: StatusOption;
    count: number;
  }[];
};

export type AssetPolicyDocumentType = "editor" | "file" | "hyperlink" | "fairo" | "plugin";
export type ExternalResourceFetchOptions = APIFetchOptionsFromFilters<
  {
    "status[]"?: ExternalResourceStatusOptions[];
  },
  BaseFilters
>;
export type ExternalResourceStatusOptions =
  | "Complete"
  | "Pending"
  | "Uncategorized"
  | "Deactivated"
  | "Syncing";

export type AuditStatus = "PENDING" | "IN PROGRESS" | "REVIEW" | "FINALIZED" | "CANCELED";

export type Audit = {
  id: string;
  audit_num: number;
  name: string;
  auditors: string[];
  auditors_name: PartialUser[];
  audit_firm: string;
  start_date: string;
  end_date: string;
  progress: number;
  framework: string;
  framework_name: PartialUser;
  use_cases: string[];
  status: AuditStatus;
  auditor_access: string;
  report_issued_date: string;
  score: number;
  comments: string;
  metadata: any;
  updated_on: string;
  created_on: string;
  created_by: PartialUser;
  updated_by: PartialUser;
};

export type AuditFetchOptions = APIFetchOptionsFromFilters<
  {
    "framework[]"?: string[];
    "status[]"?: AuditStatus[];
  },
  BaseFilters
>;

export type AuditEvidenceStatus = "NOT READY" | "READY" | "ACCEPTED" | "FLAGGED" | "EXCLUDED";

export type AuditEvidence = {
  id: string;
  audit: string;
  evidence_test: EvidenceTest;
  status: AuditEvidenceStatus;
  metadata: any;
  updated_on: string;
  created_on: string;
  created_by: PartialUser;
  updated_by: PartialUser;
};

export type AuditEvidenceFetchOptions = APIFetchOptionsFromFilters<
  {
    "type[]"?: string[];
    "audit[]"?: string[];
    "owner[]"?: string[];
    "status[]"?: AuditEvidenceStatus[];
  },
  BaseFilters
>;

export type FileAttachmentFetchOptions = APIFetchOptionsFromFilters<
  {
    parent_object_id?: string;
  },
  BaseFilters
>;

export type AuditEvidenceActivity = {
  id: string;
  related_user: PartialUser;
  activity: string;
  created_on: string;
  created_by: PartialUser;
  updated_on: string;
  updated_by: PartialUser;
};

export type AuditEvidenceActivityFetchOptions = APIFetchOptionsFromFilters<
  {
    "audit_evidence[]"?: string[];
  },
  BaseFilters
>;

export type AuditEvidenceComment = {
  id: string;
  audit_evidence: string;
  comment: string;
  created_on: string;
  created_by: PartialUser;
  updated_on: string;
  updated_by: PartialUser;
};

export type AuditEvidenceCommentFetchOptions = APIFetchOptionsFromFilters<
  {
    "audit_evidence[]"?: string[];
  },
  BaseFilters
>;

export type JiraIssue = {
  assignee: JiraUser | null;
  project: JiraProject;
  issue_type: JiraIssueType;
  summary: string;
  description: string;
};

export type EditJiraIssue = {
  assignee: string | null;
  project: string;
  issue_type: string;
  summary: string;
  description: string;
};

export type JiraProject = {
  id: string;
  name: string;
  key: string;
};

export type JiraIssueType = {
  id: string;
  name: string;
};

export type JiraUser = {
  id: string;
  name: string;
};

export type GetJiraIssue = {
  id: string;
  key: string;
  summary?: string;
  issue_type?: string;
};

export type OverallScoreOptions =
  | "Outstanding"
  | "Excellent"
  | "Very Good"
  | "Good"
  | "Above Average"
  | "Average"
  | "Below Average"
  | "Weak"
  | "Very Weak"
  | "Unacceptable";

export type OverallScoreOption = {
  score: number;
  name: OverallScoreOptions;
};

export type PlanHeirarchy = {
  index: number;
  name: string;
};

export type Vendor = {
  id: string;
  vendor_num: number;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
  name: string;
  description: string;
  potential_value: number;
  business_owner: string;
  business_owner_name: PartialUser;
  compliance_owner: string;
  compliance_owner_name: PartialUser;
  website: string;
  address: string;
  main_contact_name: string;
  main_contact_email: string;
  main_contact_phone: string;
  status: UseCaseSatusOption;
  logo: string;
  overall_risk: number;
  overall_risk_appetite: number;
  last_assessed_date: string;
  metadata: any;
  use_case_vendor_risks: UseCaseVendorRisk[];
};

export type VendorFetchOptions = APIFetchOptionsFromFilters<
  {
    "object_relates_to_id[]"?: string[];
  },
  BaseFilters
>;

export type VendorProcurementRequirementStatusOptions = "OK" | "NOT MET" | "IGNORED";

export type VendorProcurementRequirement = {
  id: string;
  name: string;
  requirement_type: RegistryFieldType;
  requirement: string;
  assignee: string;
  assignee_name: string;
  vendor: string;
  status: VendorProcurementRequirementStatusOptions;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
};

export type VendorProcurementRequirementFetchOptions = APIFetchOptionsFromFilters<
  {
    "vendor[]"?: string[];
    "assignee[]"?: string[];
    "status[]"?: string[];
  },
  BaseFilters
>;

export type PotentialHarmCategory =
  | "Health / Physical Injury"
  | "Emotional / Psycological"
  | "Loss of Opportunity"
  | "Economic Impact"
  | "Loss of Liberty"
  | "Loss of Privacy";

export type PotentialHarmSeverityOption = {
  score: number;
  name: string;
};

export type PotentialHarmScopeOption = {
  score: number;
  name: string;
};

export type PotentialHarmDisproportionalityOption = {
  score: number;
  name: string;
};

export type PotentialHarmImpactOption = {
  score: number;
  name: string;
};

export type PotentialHarmObj = {
  id: string;
  name: string;
  description: string;
  category: string;
  severity: number;
  scope: number;
  disproportionality: number;
  impact: number;
  score: number;
  last_updated_on: string;
};

export type Comment = {
  id: string;
  parent_object_id: string;
  parent_type: string;
  parent_version_id: string;
  comment: string;
  created_on: string;
  created_by: PartialUser;
  updated_on: string;
  updated_by: PartialUser;
};

export type CommentFetchOptions = APIFetchOptionsFromFilters<
  {
    parent_object_id?: string;
    parent_type?: string;
    parent_version_id?: string;
  },
  BaseFilters
>;

export type DataOriginValue = {
  internal: 1 | 2 | 3 | null;
  external: 1 | 2 | 3 | null;
};

export type ObjectCommonDataFetchOptions = APIFetchOptionsFromFilters<
  {
    parent_object_id: string;
  },
  BaseFilters
>;

export type ObjectCommonData = {
  id: string;
  parent_object_id: string;
  type: RegistryFieldType;
  metadata: any;
  assignee: string;
  assignee_name: PartialUser;
  status: string;
};

export type AssetTemplateFetchOptions = APIFetchOptionsFromFilters<
  {
    asset: string;
  },
  BaseFilters
>;

export type AssetTemplate = {
  id: string;
  value: string;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
};

export type CreateAssetTemplate = {
  asset: string;
  value: string;
};

export type ImpactAssessmentSectionTemplate = {
  id: string;
  name: string;
  description: string;
  section_num: number;
  status: SectionStatus;
  questionnaires: ImpactAssessmentQuestionnaireTemplate[];
};

export type ImpactAssessmentQuestionnaireTemplate = {
  id: string;
  name: string;
  description: string;
  status: string;
  questions: ImpactAssessmentQuestionTemplate[];
};

export type ImpactAssessmentQuestionTemplate = {
  id: string;
  title: string;
  description: string;
  type: string;
  choices: string[];
  required: boolean;
  button_label: string;
  sub_section: string;
};

export type WorkflowRun = {
  id: string;
  name: string;
  description: string | null;
  workflow_run_num: number;
  workflow: string;
  status: WorkflowRunStatus;
  process_graph: WorkflowProcessGraph;
  output: WorkflowRunOutput | null;
  created_by: PartialUser;
  created_on: string;
  updated_by: PartialUser;
  updated_on: string;
};
export type NewRegisteredModelTriggerData = {
  alias: string;
  model_name: string;
};

export type WorkflowRunOutput = {
  [key: string]:
    | {
        is_success: boolean;
        output: any;
      }
    | ({
        event: string;
      } & NewRegisteredModelTriggerData);
};

export type WorkflowRunFetchOptions = APIFetchOptionsFromFilters<
  {
    "workflow[]"?: string[];
    "status[]"?: string[];
  },
  BaseFilters
>;

export type NodeType =
  | "CREATE_JIRA_TICKET"
  | "APPROVE_JIRA_TICKET"
  | "RUN_MODEL_TEST"
  | "DEPLOY_MODEL";

export type HandlerType = "go_to" | "finish";

export type TriggerType = "NEW_REGISTERED_MODEL";

export type ConditionValue = "output" | "is_success";

export type ConditionTest = "==" | "!=" | ">" | "<";

export type WorkflowLogicCondition = {
  value: ConditionValue;
  condition_test: ConditionTest;
  test_value: string;
};

export type WorkflowHandler = {
  edge_description: string;
  type: HandlerType;
  condition: WorkflowLogicCondition[] | null;
  step: string | null;
};

export type NodeOutput = {
  output: any;
  is_success: boolean;
};

export type WorkflowNode = {
  title: string;
  description: string;
  slug: string;
  node_type: NodeType;
  position_x: number;
  position_y: number;
  handler: WorkflowHandler[];
  params:
    | CreateJiraTicketNodeParams
    | ApproveJiraTicketNodeParams
    | DeployModelNodeParams
    | RunModelTestNodeParams;
};

export type NewRegisteredModelTriggerType = {
  stage: string;
};

export type WorkflowTrigger = {
  title: string;
  description: string;
  slug: string;
  type: TriggerType;
  params: NewRegisteredModelTriggerType;
  handler: WorkflowHandler;
  position_x: number;
  position_y: number;
};

export type WorkflowProcessGraph = {
  trigger: WorkflowTrigger;
  nodes: {
    [key: string]: WorkflowNode;
  };
};

export type CreateJiraTicketNodeParams = {
  project: string;
  issue_type: string;
  summary: string;
  description: string;
  assignee: string | null;
};

export type ApproveJiraTicketNodeParams = {
  issue_id: string;
  fail_status: string;
  success_status: string;
};

export type DeployModelNodeParams = {
  model_name: string;
  staging_env_name: string;
  production_env_name: string;
  bucket_name: string;
  bucket_path: string;
};

export type RunModelTestNodeParams = {
  test_cases: string[];
};

export type WorkflowTemplate = {
  id: string;
  name: string;
  description: string;
  value: WorkflowProcessGraph;
  created_by: PartialUser;
  updated_by: PartialUser;
  created_on: string;
  updated_on: string;
};

export type WorkflowTemplateFetchOptions = APIFetchOptionsFromFilters<{}, BaseFilters>;

export type WorkflowRunStatus = "RUNNING" | "COMPLETED" | "FAILED" | "PENDING";

export type CustomFieldFetchOptions = APIFetchOptionsFromFilters<
  {
    "field_name[]"?: string[];
    "field_key[]"?: string[];
    "custom_field_field_key[]"?: string[];
  },
  BaseFilters
>;

export type UseCaseVendorRisk = {
  id: string;
  use_case: string;
  vendor: string;
  risk: number;
  expected_value: number;
  created_on: string;
  updated_on: string;
};

export type UseCaseVendorRiskFetchOptions = APIFetchOptionsFromFilters<
  {
    use_case?: string;
    vendor?: string;
  },
  BaseFilters
>;

export type ModelType = {
  name: string;
  description: string;
};

export type DatabricksModelVersion = {
  model_name: string;
  catalog_name: string;
  schema_name: string;
  source: string;
  run_id: string;
  run_workspace_id: number;
  status: string;
  version: number;
  storage_location: string;
  metastore_id: string;
  created_at: number;
  created_by: string;
  updated_at: number;
  updated_by: string;
  id: string;
};

export type FairoMLFlowModelVersion = {
  id: string;
  name: string;
  version: string;
  creation_timestamp: number;
  last_updated_timestamp: number;
  current_stage: string;
  description: string;
  source: string;
  run_id: string;
  status: string;
  tags: { key: string; value: string }[];
  run_link: string;
};

export type MLFlowModelVersion = FairoMLFlowModelVersion | DatabricksModelVersion;

export type MLFlowModelVersionResponse = {
  model_versions: MLFlowModelVersion[];
  next_page_token?: string;
};

export type MLFlowRegisteredModelResponse = {
  registered_model: {
    name: string;
    creation_timestamp: number;
    last_updated_timestamp: number;
    description: string;
    aliases: { alias: string; version: string }[];
    latest_versions: MLFlowModelVersion[];
  };
};

export type MLFlowModelVersionsFetchOptions = APIFetchOptionsFromFilters<
  {
    model_name: string;
    page_token?: string;
    max_results?: number;
    databricks?: boolean;
  },
  BaseFilters
>;

export type Subscription = {
  id: string;
  object: string;
  application: null;
  application_fee_percent: null;
  automatic_tax: SubscriptionAutomatictax;
  billing_cycle_anchor: number;
  billing_cycle_anchor_config: null;
  billing_thresholds: null;
  cancel_at: null;
  cancel_at_period_end: boolean;
  canceled_at: null;
  cancellation_details: SubscriptionCancellationdetails;
  collection_method: string;
  created: number;
  currency: string;
  current_period_end: number;
  current_period_start: number;
  customer: string;
  days_until_due: null;
  default_payment_method: string;
  default_source: null;
  default_tax_rates: any[];
  description: null;
  discount: null;
  discounts: any[];
  ended_at: null;
  invoice_settings: SubscriptionInvoicesettings;
  items: SubscriptionItems;
  latest_invoice: string | null;
  livemode: boolean;
  metadata: SubscriptionMetadata;
  next_pending_invoice_item_invoice: null;
  on_behalf_of: null;
  pause_collection: null;
  payment_settings: SubscriptionPaymentsettings;
  pending_invoice_item_interval: null;
  pending_setup_intent: null;
  pending_update: null;
  plan: SubscriptionPlan;
  quantity: number;
  schedule: null;
  start_date: number;
  status: string;
  test_clock: null;
  transfer_data: null;
  trial_end: number;
  trial_settings: SubscriptionTrialsettings;
  trial_start: number;
};

type SubscriptionTrialsettings = {
  end_behavior: SubscriptionEndbehavior;
};

type SubscriptionEndbehavior = {
  missing_payment_method: string;
};

type SubscriptionPaymentsettings = {
  payment_method_options: null;
  payment_method_types: null;
  save_default_payment_method: string;
};

type SubscriptionItems = {
  object: string;
  data: SubscriptionDatum[];
  has_more: boolean;
  total_count: number;
  url: string;
};

type SubscriptionDatum = {
  id: string;
  object: string;
  billing_thresholds: null;
  created: number;
  discounts: any[];
  metadata: SubscriptionMetadata;
  plan: SubscriptionPlan;
  price: SubscriptionPrice;
  quantity: number;
  subscription: string;
  tax_rates: any[];
};

type SubscriptionPrice = {
  id: string;
  object: string;
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  custom_unit_amount: null;
  livemode: boolean;
  lookup_key: null;
  metadata: SubscriptionMetadata;
  nickname: null;
  product: string;
  recurring: SubscriptionRecurring;
  tax_behavior: string;
  tiers_mode: null;
  transform_quantity: null;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
};

type SubscriptionRecurring = {
  aggregate_usage: null;
  interval: string;
  interval_count: number;
  meter: null;
  trial_period_days: null;
  usage_type: string;
};

type SubscriptionPlan = {
  id: string;
  object: string;
  active: boolean;
  aggregate_usage: null;
  amount: number;
  amount_decimal: string;
  billing_scheme: string;
  created: number;
  currency: string;
  interval: string;
  interval_count: number;
  livemode: boolean;
  metadata: SubscriptionMetadata;
  meter: null;
  nickname: null;
  product: string;
  tiers_mode: null;
  transform_usage: null;
  trial_period_days: null;
  usage_type: string;
};

type SubscriptionMetadata = {};

type SubscriptionInvoicesettings = {
  account_tax_ids: null;
  issuer: SubscriptionIssuer;
};

type SubscriptionIssuer = {
  type: string;
};

type SubscriptionCancellationdetails = {
  comment: null;
  feedback: null;
  reason: null;
};

type SubscriptionAutomatictax = {
  enabled: boolean;
  liability: null;
};

export type SubscriptionsFetchOptions = APIFetchOptionsFromFilters<
  {
    orgId: string;
    all_subscriptions?: boolean;
  },
  BaseFilters
>;

export type SubscriptionProduct = {
  id: string;
  object: string;
  active: boolean;
  attributes: any[];
  created: number;
  default_price: string;
  description: null | string;
  images: any[];
  livemode: boolean;
  marketing_features: SubscriptionMarketingfeature[];
  metadata: SubscriptionProductMetadata;
  name: string;
  package_dimensions: null;
  shippable: null;
  statement_descriptor: null;
  tax_code: null;
  type: string;
  unit_label: null;
  updated: number;
  url: null;
};

export type SubscriptionProductMetadata = {
  CAN_SELF_SUBSCRIBE?: string;
  "DISPLAY PRICE"?: string;
  FEATURE_LIST?: string;
  VISIBILITY?: string;
  DISPLAY_PRICE?: string;
};

export type SubscriptionMarketingfeature = {
  name: string;
};

export type SubscriptionProductPriceFetchOptions = {
  product_id: string;
};

export type SubscriptionProductPrice = {
  id: string;
  object: string;
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  custom_unit_amount: null;
  livemode: boolean;
  lookup_key: null;
  metadata: any;
  nickname: null;
  product: string;
  recurring: SubscriptionProducePriceRecurring;
  tax_behavior: string;
  tiers_mode: null;
  transform_quantity: null;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
};

type SubscriptionProducePriceRecurring = {
  aggregate_usage: null;
  interval: string;
  interval_count: number;
  meter: null;
  trial_period_days: null;
  usage_type: string;
};

export type PublicLinkState = "ACTIVE" | "DISABLED";

export type PublicLink = {
  id: string;
  name: string;
  slug: string;
  questionnaire: string;
  questionnaire_name: PartialUser;
  state: PublicLinkState;
  updated_on: string;
  updated_by: PartialUser;
  created_on: string;
  created_by: PartialUser;
  redirect_url: string | null;
};

export type PublicLinkFetchOptions = APIFetchOptionsFromFilters<
  {
    "state[]"?: string[];
  },
  BaseFilters
>;

export type PublicQuestionnaireFetchOptions = {
  session_id?: string;
};

export type DatabricksUnityCatalogModel = {
  name: string;
  catalog_name: string;
  schema_name: string;
  owner: string;
  comment: string;
  storage_location: string;
  metastore_id: string;
  full_name: string;
  created_at: number;
  created_by: string;
  updated_at: number;
  updated_by: string;
  securable_type: string;
  securable_kind: string;
  id: string;
};

export type TableMonitorAISuggestion = {
  timestamp_column: string;
  model_id_column: string;
  prediction_column: string;
  label_column: string;
  slicing_expressions: string[];
};

export type TableMonitorSuggestionsParams = {
  asset_id: string;
};

export type DatabricksTable = {
  name: string;
  catalog_name: string;
  schema_name: string;
  table_type: string;
  data_source_format: string;
  columns: DatabricksTableColumn[];
  storage_location: string;
  owner: string;
  securable_kind: string;
  generation: number;
  metastore_id: string;
  full_name: string;
  data_access_configuration_id: string;
  created_at: number;
  created_by: string;
  updated_at: number;
  updated_by: string;
  table_id: string;
  delta_runtime_properties_kvpairs: any;
  securable_type: string;
  browse_only: boolean;
};

export type DatabricksTableColumn = {
  name: string;
  type_text: string;
  type_name: string;
  position: number;
  type_precision: number;
  type_scale: number;
  type_json: string;
  nullable: boolean;
  comment?: string;
};

export type DatabricksCatalog = {
  name: string;
};

export type DatabricksSchema = {
  name: string;
};

export type DatabricksQualityMonitor = {
  output_schema_name: string;
  assets_dir: string;
  inference_log: DatabricksInferencelog;
  slicing_exprs: string[];
  table_name: string;
  status: string;
  profile_metrics_table_name: string;
  drift_metrics_table_name: string;
  dashboard_id: string;
  monitor_version: number;
};

export type DatabricksInferencelog = {
  problem_type: string;
  timestamp_col: string;
  granularities: string[];
  prediction_col: string;
  model_id_col: string;
};

export type ModelAISuggestionsParams = {
  asset_id: string;
  prompt_type: string;
};

export type ModelAISuggestion = {
  fairo_model_base_model: string;
  fairo_model_developers: string[];
  fairo_model_model_type: string;
  fairo_model_architecture: string;
  fairo_model_languages: string[];
  fairo_model_references: {
    title: string;
    url: string;
  }[];
  fairo_model_license: {
    type: string;
    title: string;
    url: string;
  };
  model_uses_overview: string;
  user_direct_use: string;
  downstream_use: string;
  out_of_scope_use: string;
  model_get_started: string;
  bias_risks_limitations_overview: string;
  recommendations: string;
  training_data: string;
  training_procedure: string;
  training_pre_processing: string;
  training_hyperparameters: string;
  training_time: string;
  computer_infrastructure: string;
  testing_data: string;
  evaluation_factors: string;
  evaluation_metrics: string;
  evaluation_summary: string;
};

export type StripeProduct = {
  id: string;
  name: string;
  stripe_product_id: string;
  fairo_settings: any;
  updated_on: string;
  updated_by: PartialUser;
  created_on: string;
  created_by: PartialUser;
};

export type StripePlan = {
  id: string;
  object: string;
  active: boolean;
  created: string;
  default_price: string;
  description: string;
  metadata: any;
  name: string;
  updated: string;
};

export type Feature = {
  module: string;
  methods: string[];
  objects: string[];
};
