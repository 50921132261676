import { IAMPolicy } from "../models/types";

export const BASE_ADMIN_POLICY: IAMPolicy[] = [
  {
    action: ["*"],
    effect: "allow",
    principal: ["*"],
  },
];

export const BASE_AUDITOR_POLICY: IAMPolicy[] = [
  {
    action: ["*:retrieve", "*:list", "audit_evidence:*", "audit:*", "reports:*"],
    effect: "allow",
    principal: ["*"],
  },
];

export const BASE_USER_POLICY: IAMPolicy[] = [
  {
    action: [
      "assets:*",
      "asset_types:*",
      "custom_fields:*",
      "custom_field_values:*",
      "files:*",
      "references:*",
      "registries:*",
      "compliance_frameworks:*",
      "compliance_elements:*",
      "element_mappings:*",
      "evidence_tests:*",
      "parameters:*",
      "parameter_values:*",
      "test_cases:*",
      "test_case_categories:*",
      "test_runs:*",
      "test_results:*",
      "metrics:*",
      "metric_categories:*",
      "metric_results:*",
      "roles:*",
      "actions:*",
      "policies:*",
      "workflows:*",
      "process_graphs:*",
      "process_nodes:*",
      "process_edges:*",
      "process_execution_plans:*",
      "process_execution_items:*",
      "tasks:*",
      "users:retrieve",
      "users:list",
      "organization:retrieve",
      "mlflow:*",
      "audit_logs:*",
      "version_history:*",
      "version_tag:*",
      "plugins:*",
      "tags:*",
      "object_tags:*",
      "webhook_messages:*",
      "resources:*",
      "resource_discovery:*",
      "concept_mapping:*",
      "notifications:*",
      "departments:*",
      "impact_assessment_types:*",
      "impact_assessments:*",
      "intercom_events:*",
      "risk_categories:*",
      "risk_scenarios:*",
      "use_cases:*",
      "plugin_data:*",
    ],
    effect: "allow",
    principal: ["*"],
    resources: ["*"],
  },
];
