import { MLFlowModelVersion } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";

export const datasetVersionColumnsVisibility: IColumnVisibility<MLFlowModelVersion>[] = [
  {
    field: "version",
    headerName: "Version",
    visible: true,
    columnMinWidth: 200,
    columnMaxWidth: 200,
    renderer: (version) => <TableTextWithEllipsis value={`Version ${version.version}`} />,
  },
];
