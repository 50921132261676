import { Box } from "@mui/material";
import SecondaryBox from "../../atoms/box/SecondaryBox";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { AssetFilesLoader } from "../../organisms/skeleton-loaders/AssetFilesLoader";
import { modelVersionColumnsVisibility } from "../../utilities/columns-visibility/AssetModelDetailColumnsVisibility";
import { ListViewLoader } from "./ListViewLoader";

export const AssetModelDetailsLoader = () => {
  return (
    <Box display="flex" flexDirection="column" gap="15px">
      <SecondaryBox>
        <AssetFilesLoader />
      </SecondaryBox>
      <SecondaryBox>
        <RectangularLoader height="40px" />
      </SecondaryBox>
      <SecondaryBox>
        <RectangularLoader height="40px" />
      </SecondaryBox>
      <SecondaryBox>
        <RectangularLoader height="40px" />
      </SecondaryBox>
      <SecondaryBox>
        <RectangularLoader height="40px" />
      </SecondaryBox>
      <ListViewLoader
        paddingBottom={false}
        columnsVisibility={modelVersionColumnsVisibility}
        tableSize="small"
      />
    </Box>
  );
};
