import { useTheme } from "@mui/material";
import { FunctionComponent, ReactNode, createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSubscription } from "./SubscriptionContext";

interface IPaywallContext {
  openPaywall: (data?: { redirectTo?: string; title?: string; dontRedirect?: boolean }) => void;
  open: boolean;
  close: () => void;
  title: string;
  learnMoreCallback: () => void;
}

const PaywallContext = createContext({} as IPaywallContext);

interface IPaywallProviderProps {
  children: ReactNode;
}

const paywallListener: {
  listner?: (data?: { redirectTo?: string; title?: string; dontRedirect?: boolean }) => void;
} = { listner: undefined };

export const openPaywall = (data?: {
  redirectTo?: string;
  title?: string;
  dontRedirect?: boolean;
}) => {
  paywallListener.listner?.(data);
};

export const PaywallProvider: FunctionComponent<IPaywallProviderProps> = ({ children }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isDemoPlan } = useSubscription();

  const dontRedirectUrls = ["/roles", "/oversight/library"];

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [redirectTo, setRedirectTo] = useState<string>("");
  const [dontRedirect, setDontRedirect] = useState<boolean>(false);
  const currentPath = window.location.pathname;
  const close = () => {
    setOpen(false);
    if (!dontRedirectUrls.some((url) => currentPath.includes(url)) && !dontRedirect) {
      navigate(redirectTo ?? "/organization/overview");
    }
  };

  const openPaywall = (data?: { redirectTo?: string; title?: string; dontRedirect?: boolean }) => {
    setOpen(true);
    setTitle(title ?? "");
    setRedirectTo(data?.redirectTo ?? "");
    setDontRedirect(data?.dontRedirect ?? false);
  };
  paywallListener.listner = openPaywall;

  const learnMoreCallback = () => {};

  return (
    <PaywallContext.Provider value={{ openPaywall, open, title, close, learnMoreCallback }}>
      {children}
    </PaywallContext.Provider>
  );
};

export const usePaywallContext = () => {
  return useContext(PaywallContext);
};
