import { format } from "date-fns";
import {
  DatabricksModelVersion,
  FairoMLFlowModelVersion,
  MLFlowModelVersion,
} from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { dateDisplayWithTime } from "../UIHelper";

export const modelVersionColumnsVisibility: IColumnVisibility<MLFlowModelVersion>[] = [
  {
    field: "version",
    headerName: "Version",
    visible: true,
    columnMinWidth: 200,
    columnMaxWidth: 200,
    renderer: (version) => <TableTextWithEllipsis value={`Version ${version.version}`} />,
  },
  {
    field: "status",
    headerName: "Version Timestamp",
    visible: true,
    columnMaxWidth: 200,
    columnMinWidth: 200,
    renderer: (version) => {
      if ((version as any)?.creation_timestamp) {
        return dateDisplayWithTime(
          format(
            new Date((version as FairoMLFlowModelVersion).creation_timestamp),
            "MM/dd/yyyy HH:mm:ss"
          )
        );
      } else {
        return dateDisplayWithTime(
          format(new Date((version as DatabricksModelVersion)?.created_at), "MM/dd/yyyy HH:mm:ss")
        );
      }
    },
  },
];
