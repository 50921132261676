import { QueryClient, useMutation, useQuery } from "react-query";
import { ICustomFieldFormData } from "../components/custom-fields/CustomFieldForm";
import { getCookie } from "../context/CookiesContext";
import { useAuth } from "../hooks/useAuth";
import {
  ActionDetailViewModel,
  ActionListViewModel,
  IActionAddRoleRequest,
  IActionRemoveRoleRequest,
  ICreateActionRequestBody,
  IGetActionListRequestBody,
  IUpdateActionRequestBody,
} from "../models/Action";
import {
  AssetDetailViewModel,
  AssetListViewModel,
  ICreateAssetRequestBody,
  IGetAssetListRequestBody,
  IUpdateAssetRequestBody,
} from "../models/Asset";
import {
  ICreateCustomFieldRequestBody,
  IUpdateCustomFieldRequestBody,
} from "../models/ICustomField";
import { ICreateOrganizationRequestBody } from "../models/IOrganization";
import { ICreateUserRequestBody } from "../models/IUser";
import {
  ICreateWorkflowRequestBody,
  IGetWorkflowListRequestBody,
  IUpdateWorkflowRequestBody,
  WorkflowDetailViewModel,
  WorkflowListViewModel,
} from "../models/IWorkflow";
import {
  ICreateRoleRequestBody,
  IGetRoleListRequestBody,
  IRoleAddUserRequest,
  IRoleRemoveUserRequest,
  IUpdateRoleRequestBody,
  RoleDetailViewModel,
  RoleListViewModel,
} from "../models/Role";
import {
  ICreateTestCaseRequestBody,
  IGetTestCaseListRequestBody,
  IUpdateTestCaseRequestBody,
  TestCaseDetailViewModel,
  TestCaseListViewModel,
} from "../models/TestCase";
import { Organization } from "../models/types";
import {
  actionAddRole,
  actionCustomFieldMethods,
  actionFileMethods,
  actionMethods,
  actionRemoveRole,
  assetCustomFieldMethods,
  assetFileMethods,
  assetMethods,
  createCustomField,
  createOrganization,
  getAssetTypes,
  getCustomField,
  getCustomFields,
  getInvitedUsers,
  getJoinedUsers,
  getOrganization,
  getOrganizations,
  getStripeKey,
  getUserByID,
  getUsers,
  processExecutionPlanCustomFieldMethods,
  processGraphCustomFieldMethods,
  processGraphFileMethods,
  processNodeCustomFieldMethods,
  processNodeFileMethods,
  registerUserInOrganization,
  roleAddUser,
  roleCustomFieldMethods,
  roleFileMethods,
  roleMethods,
  roleRemoveUser,
  testCaseCustomFieldMethods,
  testCaseFileMethods,
  testCaseMethods,
  updateCustomField,
  workflowCustomFieldMethods,
  workflowFileMethods,
  workflowMethods,
} from "../services/DataService";
import { CreateOrUpdateResult } from "../utilities/ApiResponseHelper";
import { objectCustomFieldHooks, objectFileAttachmentHooks, objectHooks } from "./QueryStoreHelper";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: 2,
    },
  },
});

/**unique keys, lets react-query know when to use cache */
export enum QueryKey {
  organizationGet = "organization/get",
  usersGet = "users/get",
  workflowsGet = "workflows/get",
  processGraphsGet = "processGraphs/get",
  processPlanGet = "processPlan/get",
  processNodesGet = "processNodes/get",
  rolesGet = "roles/get",
  actionsGet = "actions/get",
  testCasesGet = "testCases/get",
  assetsGet = "assets/get",
  assetTypesGet = "assetTypes/get",
  customFieldsGet = "customFields/get",
  jiraIntegrationGet = "jiraIntegration/get",
  TestCase = "test-case",
  TestResult = "test-result",
  Metric = "metric",
  MetricCategory = "metric-category",
  TestRun = "test-run",
  Asset = "asset",
  Role = "role",
  Action = "action",
  Workflow = "workflow",
  CustomField = "custom-field",
  ProcessGraph = "process-graph",
  ProcessNode = "process-node",
  ExecutionPlan = "execution-plan",
  ExecutionItem = "execution-item",
  ExecutionTask = "execution-task",
  User = "user",
  Parameter = "parameters",
  MetricResult = "metric-result",
  ParameterValue = "parameter-value",
  Reference = "reference",
  ElementMapping = "element-mapping",
  EvidenceMapping = "evidence-mapping",
  ComplianceElement = "compliance-element",
  ComplianceFramework = "compliance-framework",
  ComplianceElementType = "compliance-element-type",
  MLFlowModel = "mlflow-model",
  TrialPlans = "trial-plan",
  Policy = "policy",
  AuditLog = "audit-log",
  AssetTypes = "asset-types",
  VersionHistory = "version-history",
  VersionTag = "version-tag",
  Plugins = "plugins",
  RatingLevelOptions = "rating-level-options",
  Tag = "tag",
  ObjectTag = "object-tag",
  PluginsBuckets = "plugins-buckets",
  File = "file",
  CustomFieldValue = "custom-field-value",
  EvidenceRequirement = "evidence-requirement",
  ResourceDiscovery = "resource-discovery",
  EvidenceRequirementTestHistory = "evidence-requirement-test-history",
  Resource = "resource",
  ConceptMapping = "concept-mapping",
  WebhookMessage = "webhook-message",
  WorkedOn = "worked-on",
  Viewed = "viewed",
  AssignedToMe = "assigned-to-me",
  Questionnaire = "questionnaire",
  TypeformForm = "typeform-form",
  QuestionnaireResponses = "questionnaire-responses",
  Notification = "notification",
  Report = "report",
  Department = "department",
  ImpactAssessmentType = "impact-assessment-type",
  ImpactAssessment = "impact-assessment",
  QuestionnaireAnswer = "questionnaire-answer",
  IntercomEvent = "intercom-event",
  RiskCategory = "risk-category",
  RiskScenario = "risk-scenario",
  UseCase = "use-case",
  RiskRegistry = "risk-registry",
  QuestionnaireSection = "questionnaire-section",
  EvidenceTestResource = "evidence-test-resource",
  PluginData = "plugin-data",
  Audit = "audit",
  AuditEvidence = "audit-evidence",
  AuditEvidenceActivity = "audit-evidence-activity",
  AuditEvidenceComment = "audit-evidence-comment",
  JiraProjects = "jira-projects",
  JiraUsers = "jira-users",
  JiraIssueTypes = "jira-issue-types",
  UserPermission = "user-permission",
  Vendor = "vendor",
  Comment = "comment",
  VendorProcurementRequirement = "vendor-procurement-requirement",
  CommonData = "common-data",
  AssetTemplate = "asset-template",
  WorkflowRun = "workflow-run",
  WorkflowTemplate = "workflow-template",
  UseCaseVendorRisk = "use-case-vendor-risk",
  MlflowVersions = "mlflow-versions",
  Subscription = "subscription",
  SubscriptionProducts = "subscription-products",
  SubscriptionProductPrice = "subscription-product-price",
  PublicLink = "public-link",
  PublicQuestionnaire = "public-questionnaire",
  Databricks = "databricks",
  StripeProduct = "stripe-product",
  StripePlan = "stripe-plan",
}

//#region [Organization]
const useOrganizationGet = () => {
  const { user, loggedIn } = useAuth();
  const userOrgId = user?.organization;
  const organization_id = getCookie("organization_id");
  return useQuery(
    [QueryKey.organizationGet, userOrgId],
    () => getOrganization(organization_id ? organization_id : userOrgId!),
    {
      enabled: !!loggedIn && !!userOrgId,
    }
  );
};

const useOrganizationsGet = (params: any) => {
  const { loggedIn } = useAuth();
  return useQuery(
    [QueryKey.organizationGet, params],
    async () => {
      const { data } = await getOrganizations(params);
      return data;
    },
    {
      enabled: !!loggedIn,
    }
  );
};

const useStripeKeyGet = () => useQuery(QueryKey.organizationGet, getStripeKey);

const useOrganizationCreate = () =>
  useMutation<CreateOrUpdateResult<Organization>, unknown, ICreateOrganizationRequestBody>(
    (org) => createOrganization(org),
    {
      onSuccess: () => {
        queryClient.refetchQueries(QueryKey.organizationGet, {
          active: true,
          exact: true,
        });
      },
    }
  );

const useOrganizationUsersGet = (page: number, page_size: number) =>
  useQuery([QueryKey.usersGet, page, page_size], () => getUsers(page, page_size), {});

const useOrganizationActiveUsersGet = (page: number, page_size: number) =>
  useQuery(
    [QueryKey.usersGet, "ACTIVE", page, page_size],
    () => getJoinedUsers(page, page_size),
    {}
  );

const useOrganizationInvitedUsersGet = (page: number, page_size: number) =>
  useQuery(
    [QueryKey.usersGet, "INVITED", page, page_size],
    () => getInvitedUsers(page, page_size),
    {}
  );

const useOrganizationUserGet = (userID: string) =>
  useQuery([QueryKey.usersGet, userID], () => getUserByID(userID), {});

const useOrganizationUserCreate = (organizationId: string) =>
  useMutation<CreateOrUpdateResult<any>, unknown, ICreateUserRequestBody>(
    (data) => registerUserInOrganization(organizationId, data),
    {
      onSuccess: () => {
        queryClient.refetchQueries(QueryKey.usersGet);
      },
    }
  );
//#endregion

//#region [Workflow]
const workflowHooks = objectHooks<
  WorkflowListViewModel,
  WorkflowDetailViewModel,
  ICreateWorkflowRequestBody,
  IUpdateWorkflowRequestBody,
  IGetWorkflowListRequestBody
>({
  methods: workflowMethods as any,
  baseQueryKey: QueryKey.workflowsGet,
  listViewModelConverter(data) {
    return new WorkflowListViewModel(data);
  },
  detailViewModelConverter(data) {
    return new WorkflowDetailViewModel(data);
  },
});
//#endregion

//#region [Role]
const roleHooks = objectHooks<
  RoleListViewModel,
  RoleDetailViewModel,
  ICreateRoleRequestBody,
  IUpdateRoleRequestBody,
  IGetRoleListRequestBody
>({
  methods: roleMethods as any,
  baseQueryKey: QueryKey.rolesGet,
  listViewModelConverter(data) {
    return new RoleListViewModel(data);
  },
  detailViewModelConverter(data) {
    return new RoleDetailViewModel(data);
  },
});

const useRoleAddUser = () =>
  useMutation<any, any, IRoleAddUserRequest>((params) => roleAddUser(params), {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.rolesGet]);
    },
  });

const useRoleRemoveUser = () =>
  useMutation<any, any, IRoleRemoveUserRequest>((params) => roleRemoveUser(params), {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.rolesGet]);
    },
  });
//#endregion

//#region [Action]
const actionHooks = objectHooks<
  ActionListViewModel,
  ActionDetailViewModel,
  ICreateActionRequestBody,
  IUpdateActionRequestBody,
  IGetActionListRequestBody
>({
  methods: actionMethods as any,
  baseQueryKey: QueryKey.actionsGet,
  listViewModelConverter(data) {
    return new ActionListViewModel(data);
  },
  detailViewModelConverter(data) {
    return new ActionDetailViewModel(data);
  },
});

const useActionAddRole = () =>
  useMutation<any, any, IActionAddRoleRequest>((params) => actionAddRole(params), {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.actionsGet]);
    },
  });

const useActionRemoveRole = () =>
  useMutation<any, any, IActionRemoveRoleRequest>((params) => actionRemoveRole(params), {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.actionsGet]);
    },
  });
//#endregion

//#region [TestCase]
const testCaseHooks = objectHooks<
  TestCaseListViewModel,
  TestCaseDetailViewModel,
  ICreateTestCaseRequestBody,
  IUpdateTestCaseRequestBody,
  IGetTestCaseListRequestBody
>({
  methods: testCaseMethods as any,
  baseQueryKey: QueryKey.testCasesGet,
  listViewModelConverter(data) {
    return new TestCaseListViewModel(data);
  },
  detailViewModelConverter(data) {
    return new TestCaseDetailViewModel(data);
  },
});
//#endregion

//#region [TestCase]
const assetHooks = objectHooks<
  AssetListViewModel,
  AssetDetailViewModel,
  ICreateAssetRequestBody,
  IUpdateAssetRequestBody,
  IGetAssetListRequestBody
>({
  methods: assetMethods as any,
  baseQueryKey: QueryKey.assetsGet,
  listViewModelConverter(data) {
    return new AssetListViewModel(data);
  },
  detailViewModelConverter(data) {
    return new AssetDetailViewModel(data);
  },
});

const useAssetTypesGet = (params: { fairo_data?: boolean }) =>
  useQuery([QueryKey.assetTypesGet], () => getAssetTypes(params));
//#endregion

//#region [CustomField]
const useCustomFieldsGet = () => useQuery([QueryKey.customFieldsGet], () => getCustomFields(), {});

const useCustomFieldGet = (customFieldId: string) =>
  useQuery([QueryKey.customFieldsGet, customFieldId], () => getCustomField(customFieldId));

const useCustomFieldCreate = () =>
  useMutation<CreateOrUpdateResult<ICustomFieldFormData>, unknown, ICreateCustomFieldRequestBody>(
    (field) => createCustomField(field),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.customFieldsGet]);
      },
    }
  );

const useCustomFieldUpdate = (customFieldId: string) =>
  useMutation<CreateOrUpdateResult<ICustomFieldFormData>, unknown, IUpdateCustomFieldRequestBody>(
    (customField) => updateCustomField(customFieldId, customField),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.customFieldsGet]);
      },
    }
  );
//#endregion

//#region [ObjectCustomField]
const workflowCustomFieldHooks = objectCustomFieldHooks({
  methods: workflowCustomFieldMethods,
  onSuccessInvalidateQueries: [QueryKey.workflowsGet],
});

const processGraphCustomFieldHooks = objectCustomFieldHooks({
  methods: processGraphCustomFieldMethods,
  onSuccessInvalidateQueries: [QueryKey.processGraphsGet],
});

const processNodeCustomFieldHooks = objectCustomFieldHooks({
  methods: processNodeCustomFieldMethods,
  onSuccessInvalidateQueries: [QueryKey.processNodesGet],
});

const roleCustomFieldHooks = objectCustomFieldHooks({
  methods: roleCustomFieldMethods,
  onSuccessInvalidateQueries: [QueryKey.rolesGet],
});

const actionCustomFieldHooks = objectCustomFieldHooks({
  methods: actionCustomFieldMethods,
  onSuccessInvalidateQueries: [QueryKey.actionsGet],
});

const testCaseCustomFieldHooks = objectCustomFieldHooks({
  methods: testCaseCustomFieldMethods,
  onSuccessInvalidateQueries: [QueryKey.testCasesGet],
});

const assetCustomFieldHooks = objectCustomFieldHooks({
  methods: assetCustomFieldMethods,
  onSuccessInvalidateQueries: [QueryKey.assetsGet],
});

const processExecutionPlanCustomFieldHooks = objectCustomFieldHooks({
  methods: processExecutionPlanCustomFieldMethods,
  onSuccessInvalidateQueries: [QueryKey.ExecutionPlan],
});
//#endregion

//#region [ObjectFileAttachments]
const workflowFileAttachmentHooks = objectFileAttachmentHooks({
  methods: workflowFileMethods,
  onSuccessInvalidateQueries: [QueryKey.workflowsGet],
});

const processGraphFileAttachmentHooks = objectFileAttachmentHooks({
  methods: processGraphFileMethods,
  onSuccessInvalidateQueries: [QueryKey.processGraphsGet],
});

const processNodeFileAttachmentHooks = objectFileAttachmentHooks({
  methods: processNodeFileMethods,
  onSuccessInvalidateQueries: [QueryKey.processNodesGet],
});

const roleFileAttachmentHooks = objectFileAttachmentHooks({
  methods: roleFileMethods,
  onSuccessInvalidateQueries: [QueryKey.rolesGet],
});

const actionFileAttachmentHooks = objectFileAttachmentHooks({
  methods: actionFileMethods,
  onSuccessInvalidateQueries: [QueryKey.actionsGet],
});

const testCaseFileAttachmentHooks = objectFileAttachmentHooks({
  methods: testCaseFileMethods,
  onSuccessInvalidateQueries: [QueryKey.testCasesGet],
});

const assetFileAttachmentHooks = objectFileAttachmentHooks({
  methods: assetFileMethods,
  onSuccessInvalidateQueries: [QueryKey.assetsGet],
});
//#endregion

export default queryClient;
export {
  actionCustomFieldHooks,
  actionFileAttachmentHooks,
  actionHooks,
  assetCustomFieldHooks,
  assetFileAttachmentHooks,
  assetHooks,
  processExecutionPlanCustomFieldHooks,
  processGraphCustomFieldHooks,
  processGraphFileAttachmentHooks,
  processNodeCustomFieldHooks,
  processNodeFileAttachmentHooks,
  roleCustomFieldHooks,
  roleFileAttachmentHooks,
  roleHooks,
  testCaseCustomFieldHooks,
  testCaseFileAttachmentHooks,
  testCaseHooks,
  useActionAddRole,
  useActionRemoveRole,
  useAssetTypesGet,
  useCustomFieldCreate,
  useCustomFieldGet,
  useCustomFieldsGet,
  useCustomFieldUpdate,
  useOrganizationActiveUsersGet,
  useOrganizationCreate,
  useOrganizationGet,
  useOrganizationInvitedUsersGet,
  useOrganizationsGet,
  useOrganizationUserCreate,
  useOrganizationUserGet,
  useOrganizationUsersGet,
  useRoleAddUser,
  useRoleRemoveUser,
  useStripeKeyGet,
  workflowCustomFieldHooks,
  workflowFileAttachmentHooks,
  workflowHooks,
};
